import api from '../../services/ApiService'
import store from '../store'
import lodash from 'lodash'

const state = {
    engines: [],
    filteredEngines: []
};

const getters = {
    engines() {
        return state.engines;
    },
    filteredEngines() {
        return state.filteredEngines;
    }   
};

const actions = {
    fetchEngines({ commit }, trimLevelId) {
        store.dispatch('loading/setLoading', true);
        return api.getEngines(trimLevelId).then(engines => {
            commit('setEngines', engines);
            store.dispatch('loading/setLoading', false);
        });
    },
    categorizeEnginesBy({ commit }, categorizationCriteria) {
        let categorizedEngines = [];

        state.engines.forEach(e => {

            let categoryIndex = -1;
            categorizedEngines.some((c, i) => {
                if (c.category === e[categorizationCriteria]) {
                    categoryIndex = i;
                    return true;
                } else {
                    return false;
                }
            });


            if(categoryIndex > -1) {
                categorizedEngines[categoryIndex].engines.push(e);
            }else {
                categorizedEngines.push({
                    category: e[categorizationCriteria],
                    engines: [e]
                });
            }
        });


        categorizedEngines = lodash.orderBy(categorizedEngines, ['category']);
        categorizedEngines.forEach((c) => {
            c.engines = lodash.orderBy(c.engines, ['min_price_gross']);
        });

        commit('setFilteredEngines', categorizedEngines);    
    }
};

const mutations = {
    setEngines(state, engines) {
        state.engines = engines;
    },
    setFilteredEngines(state, engines) {
        state.filteredEngines = engines;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};