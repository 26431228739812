import Vue from 'vue';
import Router from 'vue-router';
import config from '../config'
import Makers from '../components/pages/Makers';
import Models from '../components/pages/Models';
import BodyDesigns from '../components/pages/BodyDesigns';
import TrimLevels from '../components/pages/TrimLevels';
import Engines from '../components/pages/Engines';
import Equipment from '../components/pages/Equipment';
import Summary from '../components/pages/Summary';
import Offer from '../components/pages/Offer'
Vue.use(Router);

const router = new Router({
    scrollBehavior: () => {
        return new Promise((resolve, reject) => {
            if(config.scrollToTop) {
                resolve({x:0, y:0, behavior: 'smooth'});
            }else {
                reject();
            }
        });
                    
    },
    routes: [
        {
            path: '/Hersteller',
            name: 'makers',
            component: Makers,
        },
        {
            path: '/Hersteller/:maker/Modelle',
            component: Models,
            name: 'models',
            props: true
        },
        {
            path: '/Hersteller/:maker/Modell/:model/Karosserie',
            component: BodyDesigns,
            name: 'body_designs',
            props: true
        },
        {
            path: '/Hersteller/:maker/Modell/:model/Karroserie/:bodyDesign/Ausstattungslinien',
            component: TrimLevels,
            name: 'trim_levels',
            props: true
        },
        {
            path: '/Hersteller/:maker/Modell/:model/Karroserie/:bodyDesign/Ausstattungslinie/:trimLevel/Motoren',
            component: Engines,
            name: 'engines',
            props: true
        },
        {
            path: '/Hersteller/:maker/Modell/:model/Karroserie/:bodyDesign/Ausstattungslinie/:trimLevel/Motor/:engine',
            component: Equipment,
            name: 'equipment',
            props: true
        },
        {
            path: '/:configurationId/Zusammenfassung/:offer?',
            component: Summary,
            name: 'summary',
            props: true
        },
        {
            path: '/:configurationId/Anfrage',
            component: Offer,
            name: 'offer',
            props: true
        }
    ]
});


export default router;