<template>
  <b-card
    v-if="maker && model && bodyDesign && trimLevel && engine"
    class="cd-car-info-card"
  >
    <b-container class="p-0">
      <b-row class="p-0">
            <b-col cols="12"
            sm="12"
            md="6"
            >
              <h2>{{ maker ? maker.label : "" }} {{ model ? model.label : "" }}</h2>
              <p class="cd-car-info-subline">
                {{ engine.label }}
                <span class="cd-efficiency-mark" :id="'energyefficiency-target-'+engine.id">
                  <span :class="'mark-background ' + calculateEnergyClass(engine)"></span>              
                  <span class="cd-efficiency-marklabel">{{getCo2Class(engine)}}</span>

                  <span v-if="hasSecondaryCo2Class(engine)"
                      :class="'mark-background secondary ' +calculateSecondaryEnergyClass(engine)"
                    ></span>
                    <span v-if="hasSecondaryCo2Class(engine)" class="cd-efficiency-marklabel secondary">{{
                      secondaryCo2Class(engine)
                    }}</span>
                  <b-popover :target="'energyefficiency-target-'+engine.id" triggers="hover">
                    <EnergyEfficiencyBox :engine="engine" />
                  </b-popover>              
                </span>
              </p>
            </b-col>
         </b-row>

           <b-row cols="12" align-h="center" align-v="center" class="d-flex flex-wrap p-0">


              <b-col class="cd-carinfo-spec"

              >
                <img class="cd-carinfo-icon" :src="assetUrl+enginePowerIcon" />
                <p class="cd-carinfo-data-label cd-primary-color">
                  {{ engine.technical_data.max_power_kw }}kW/{{ engine.technical_data.max_power_ps }}PS
                </p>
                <p class="cd-carinfo-data-label-description">Leistung</p>
              </b-col>
              <b-col class="cd-carinfo-spec"
              >
                <img class="cd-carinfo-icon" :src="assetUrl+fuelTypeIcon" />
                <p class="cd-carinfo-data-label cd-primary-color">
                  {{ engine.fuel_type }}
                </p>
                <p class="cd-carinfo-data-label-description">Kraftstoff</p>
              </b-col>
              <b-col class="cd-carinfo-spec"
              >
                <img class="cd-carinfo-icon" :src="assetUrl+transmissionIcon" />
                <p class="cd-carinfo-data-label cd-primary-color">
                  {{ engine.transmission}}
                </p>
                <p class="cd-carinfo-data-label-description">Getriebe</p>
              </b-col>
          <b-col class="cd-carinfo-visualise "
          sm="10"
          md="4"
          > 
              <CarImage class="rounded" :imageUrl="carImageUrl" />
          </b-col>
        </b-row>
    </b-container>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import imageService from '../../services/ImageService'
import config from '../../config'
import CarImage from '../elements/CarImage.vue';
import EnergyEfficiencyBox from "../elements/EnergyEfficiencyBox.vue";
import lodash from 'lodash';
import ValueService from '../../services/ValueService';

export default {
  created() {},
  computed: {
    ...mapGetters({
      maker: "carConfiguration/currentMaker",
      model: "carConfiguration/currentModel",
      bodyDesign: "carConfiguration/currentBodyDesign",
      trimLevel: "carConfiguration/currentTrimLevel",
      engine: "carConfiguration/currentEngine",
      equipment: "equipment/equipment",
    }),
    assetUrl() {
      return config.assetUrl;
    },
    selectedColor() {
      return lodash.filter(this.equipment, {category_name: 'Farben', selected: true})[0];
    },
    carImageUrl() {
        let value = this.getCarImage(this.trimLevel, this.selectedColor);

        return value;
    },
  },
  methods: {
    calculateEnergyClass(engine) {
      if (!engine) {
        return "-";
      }

      let energyClass = engine.technical_data.energy_efficiency;
      let cssClass = "efficiency-";

      if(ValueService.canCalculateCo2Class(engine)) {
        energyClass = ValueService.getWltpCo2Class(engine);

        cssClass = `${cssClass}wltp-`
      }

      let c = energyClass.toLowerCase();
      
      c = c.replace(/\+/g, "p");

      return cssClass + c;
    },
    getCo2Class(engine) {
      return ValueService.getWltpCo2Class(engine);
    },
    secondaryCo2Class(engine) {
      if(engine.technical_data.wltpData && engine.technical_data.wltpData.calculations.secondaryCo2Class) {
        return engine.technical_data.wltpData.calculations.secondaryCo2Class;
      }

      return "";
    },
    hasSecondaryCo2Class(engine) {
      return engine.technical_data.wltpData && engine.technical_data.wltpData.calculations.secondaryCo2Class;
    },
    calculateSecondaryEnergyClass(engine) {
      if(engine.technical_data.wltpData) {
        let energyClass = engine.technical_data.wltpData.calculations.secondaryCo2Class.toLowerCase();
        let cssClass = "efficiency-wltp-";

        return cssClass+energyClass;
      }

      return "";
    },
    getCarImage(engine, selectedColor) {
      return imageService.buildImageUrlForPerspective(engine, 0, selectedColor, this.maker, this.model);
    },    
  },
  data() {
    return {
      enginePowerIcon: require("../../assets/images/motor_power.svg"),
      fuelTypeIcon: require("../../assets/images/fuel.svg"),
      transmissionIcon: require("../../assets/images/transmission.svg"),
    };
  },
  watch: {
    selectedColor: function() {
      //this.$forceUpdate();
    }
  },
  components:{
    CarImage,
    EnergyEfficiencyBox
  }
};
</script>

<style>
.cd-car-info-card {
  margin: 56px 0px;
  background-color: #f9f9f9 !important;

  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 15px !important;
}


.cd-car-info-card .card-body h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
}

.cd-car-info-subline {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: #3c3c3c;
}

.cd-efficiency-mark {
  width: 41px;
  height: 21px;
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  color: #fff;
  text-align: center;

  margin-left: 20px;
}

.cd-efficiency-mark .mark-background {
  left: 0;
  top: 5px;
  background: url("./../../assets/images/energy_marker.svg") no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
}

.cd-efficiency-marklabel {
  left: 0;
  top: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.cd-efficiency-mark .mark-background.secondary {
  left: 50px;
} 

.cd-efficiency-marklabel.secondary{
  left: 50px;
}

.cd-efficiency-mark .mark-background.efficiency-appp {
  filter: invert(35%) sepia(80%) saturate(2677%) hue-rotate(133deg) brightness(96%) contrast(104%);

}
.cd-efficiency-mark .mark-background.efficiency-app {
  filter: invert(35%) sepia(80%) saturate(2677%) hue-rotate(133deg) brightness(96%) contrast(104%);
  
}
.cd-efficiency-mark .mark-background.efficiency-ap {
  filter: invert(49%) sepia(99%) saturate(308%) hue-rotate(67deg) brightness(103%) contrast(91%);
}

.cd-efficiency-mark .mark-background.efficiency-a {
  filter: invert(99%) sepia(40%) saturate(2853%) hue-rotate(10deg) brightness(89%) contrast(86%);
}

.cd-efficiency-mark .mark-background.efficiency-b {
  filter: invert(99%) sepia(40%) saturate(2853%) hue-rotate(10deg) brightness(89%) contrast(86%);
}

.cd-efficiency-mark .mark-background.efficiency-c {
  filter: invert(99%) sepia(31%) saturate(5919%) hue-rotate(346deg) brightness(100%) contrast(105%);
}

.cd-efficiency-mark .mark-background.efficiency-d {
  filter: invert(55%) sepia(82%) saturate(2545%) hue-rotate(345deg) brightness(96%) contrast(97%);
}

.cd-efficiency-mark .mark-background.efficiency-e {
  filter: invert(12%) sepia(97%) saturate(5764%) hue-rotate(353deg) brightness(99%) contrast(88%);
}

.cd-efficiency-mark .mark-background.efficiency-f {
  filter: invert(24%) sepia(80%) saturate(4754%) hue-rotate(345deg) brightness(90%) contrast(107%);
}

.cd-efficiency-mark .mark-background.efficiency-g {
  filter: invert(22%) sepia(99%) saturate(6390%) hue-rotate(351deg) brightness(96%) contrast(93%);
}

.cd-efficiency-mark .mark-background.efficiency-wltp-a {
  filter: brightness(0) saturate(100%) invert(42%) sepia(48%) saturate(4151%) hue-rotate(126deg) brightness(94%) contrast(98%);
}

.cd-efficiency-mark .mark-background.efficiency-wltp-b {
  filter: brightness(0) saturate(100%) invert(64%) sepia(70%) saturate(478%) hue-rotate(63deg) brightness(87%) contrast(86%);
}

.cd-efficiency-mark .mark-background.efficiency-wltp-c {
  filter: brightness(0) saturate(100%) invert(73%) sepia(100%) saturate(404%) hue-rotate(18deg) brightness(96%) contrast(90%);
}

.cd-efficiency-mark .mark-background.efficiency-wltp-d {
  filter: brightness(0) saturate(100%) invert(76%) sepia(85%) saturate(1141%) hue-rotate(5deg) brightness(110%) contrast(107%);
}

.cd-efficiency-mark .mark-background.efficiency-wltp-e {
  filter: brightness(0) saturate(100%) invert(77%) sepia(58%) saturate(2601%) hue-rotate(354deg) brightness(102%) contrast(97%);
}

.cd-efficiency-mark .mark-background.efficiency-wltp-f {
  filter: brightness(0) saturate(100%) invert(38%) sepia(80%) saturate(1241%) hue-rotate(350deg) brightness(97%) contrast(96%);
}

.cd-efficiency-mark .mark-background.efficiency-wltp-g {
  filter: brightness(0) saturate(100%) invert(17%) sepia(67%) saturate(7057%) hue-rotate(351deg) brightness(96%) contrast(91%);
}


.cd-carinfo-spec {
  text-align: center;
  margin-bottom: 2rem;
}

.cd-carinfo-spec.no-margin {
    margin-bottom:0px;
}

.cd-carinfo-icon {
  width: 26px;
  filter: invert(13%) sepia(65%) saturate(3972%) hue-rotate(207deg)
    brightness(105%) contrast(114%);
}

.cd-carinfo-data-label-description {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #3c3c3c;
}

.cd-carinfo-data-label {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  margin-bottom:0px;
}





</style>