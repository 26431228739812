export default {
    trackStage(stage, value) {
        debugger;// eslint-disable-line no-debugger
        let ev = new CustomEvent("configuration-stage-changed", {
            detail: {
                stage: stage,
                value: value
            }
        });

        window.dispatchEvent(ev);
    }
}