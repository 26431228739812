import api from '../../services/ApiService'
import store from '../store'
import lodash from 'lodash';

const state = {
    equipment: [],
    categorizedEquipment: []
};

const getters = {
    equipment() {
        return state.equipment;
    },
    categorizedEquipment() {
        return state.categorizedEquipment;
    }
};
/* eslint-disable no-debugger */
const actions = {
    fetchEquipment({ commit }, engineId) {
        store.dispatch('loading/setLoading', true);
        return api.getExtras(engineId).then(extras => {
            extras.forEach(e => {
                e.selected = false;
            });
            commit('setEquipment', extras);
            store.dispatch('loading/setLoading', false);
        });
    },
    categorizeEquipment({ commit }, currentExtras) {
        let tmpCategorizedEquipment = [];
        const excludedCategories = ['Hidden'];

        let selectedExtras = [];

        if(currentExtras && currentExtras.length > 0) {
            selectedExtras = lodash.filter(currentExtras, {selected: true});
        }

        state.equipment.forEach(e => {

            let categoryIndex = -1;
            tmpCategorizedEquipment.some((c, i) => {
                if (c.category_name === e.category_name) {
                    categoryIndex = i;
                    return true;
                } else {
                    return false;
                }
            });
            
            const selected = lodash.some(selectedExtras, {id: e.id});
            e.selected = selected;

            if (categoryIndex > -1) {
                tmpCategorizedEquipment[categoryIndex].equipment.push(e);
            } else {
                if (excludedCategories.indexOf(e.category_name) === -1)
                    tmpCategorizedEquipment.push({
                        category_name: e.category_name,
                        equipment: [e]
                    });
            }
        });

        tmpCategorizedEquipment = lodash.orderBy(tmpCategorizedEquipment, [(o) => { return o.category_name !== 'Farben'}, 'category_name']);

        return commit('setCategorizedEquipment', tmpCategorizedEquipment);
    }
};

const mutations = {
    setEquipment(state, equipment) {
        state.equipment = equipment;
    },
    setCategorizedEquipment(state, categorizedEquipment) {
        state.categorizedEquipment = categorizedEquipment;
    },
    selectEquipment(state, payload) {
        state.equipment.some(e => {
            if (e.id === payload.id) {
                e.selected = payload.selected;
                return true;
            }
            return false;
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};