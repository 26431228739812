<template>
  <div className="cd-configurator-main">
    <b-container class="cd-configurator-container">
      <b-container class="stepper">
        <Stepper :steps="maxProgress" :currentStep="currentProgress" />
      </b-container>
      <b-container class="header">
        <b-row class="">
          <b-col class :cols="isCompactLayout ? 10 : 12">
            <div class="cd-progress">      
              {{ progress }}
            </div>
          </b-col>
        </b-row>
        <b-row class=" d-header-section">
          <b-col class="pt-0 col-12 col-md-10">  
              <h1 class="h3 font-weight-bold"
                >{{ progressSubline }}
              </h1>
          </b-col>
          <b-col class="col-12 col-md-2 p-0 m-0 d-flex justify-content-end">
            <div class="d-none d-md-block">
              <p class="cd-max-progress cd-primary-color">
                {{ currentProgress }} / {{ maxProgress }}
              </p>
             </div> 
          </b-col>
        </b-row>
        <hr />
        <b-row>  
          <b-col>

            <div class="cd-selection-text" v-if="currentProgress < 7">
              <span class="cd-current-selectiontext">{{
                currentSelection
              }}</span>
            </div>
            <div
              class="cd-equipment-compairsion-link"
              v-if="showEquipmentCompairsionLink"
            >
              <a
                href=""
                class="cd-primary-color"
                @click.prevent="openEquipmentCompairsion()"
                >Vergleichen</a
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
          <Loader />
      <Sidebar v-if="isCompactLayout" />
      <b-container fluid classs="cd-car-info" v-if="showCarInfo">
        <CarInfo />
      </b-container>
      <b-container fluid class="cd-application-frame">
        <router-view></router-view>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import AuthService from "./services/AuthService";
import { mapGetters, mapActions} from "vuex";
import Loader from "./components/utils/Loader.vue";
import eventBus from "./eventing/eventBus";
import CarInfo from ".//components/elements/CarInfo.vue";
import Sidebar from "./components/elements/Sidebar.vue";
import Stepper from "./components/elements/Stepper.vue";

export default {
  name: "App",
  created() {
    const accessToken = this.$root.$el.dataset["auth"];
    const privacyPolicy = this.$root.$el.dataset["privacypolicy"]
    console.log(privacyPolicy);
    this.$store.commit("auth/accessToken", accessToken);
    this.$store.commit("setPrivacyPolicyUrl", privacyPolicy);
  },
  mounted() {
    const vm = this;
    const router = this.$router;
    AuthService.authenticate().then((res) => {
      if (res) {
        const tenant = res.user;
        this.$store.commit("auth/tenant", tenant);

        vm.fetchConditions().then(() => {
          if (router.history.current.fullPath === "/") {
            router.push({ name: "makers" });
          }
        });

        if (router.history.current.fullPath === "/") {
          router.push({ name: "makers" });
        }
      }
    });
  },
  methods: {
    ...mapActions({
      fetchConditions: 'conditions/fetchConditions'
    }),
    openEquipmentCompairsion() {
      eventBus.$emit("open-equipment-compairsion");
    },
  },
  computed: {
    ...mapGetters({
      progress: "progress",
      currentProgress: "currentProgress",
      maxProgress: "maxProgress",
      progressSubline: "progressSubline",
      currentMaker: "carConfiguration/currentMaker",
      currentModel: "carConfiguration/currentModel",
      currentBodyDesign: "carConfiguration/currentBodyDesign",
      currentTrimLevel: "carConfiguration/currentTrimLevel",
      showEquipmentCompairsionLink: "showEquipmentCompairsionLink",
      showCarInfo: "showCarInfo",
      showSidebar: "showSidebar"
    }),
    /* eslint-disable no-debugger */
    isCompactLayout() {
    
      return this.currentProgress > 6;
    },
    currentSelection() {
      let selectionText = "";

      if (this.currentMaker) {
        selectionText += this.currentMaker.label;
      }

      if (this.currentModel) {
        selectionText += ", " + this.currentModel.label;
      }

      if (this.currentBodyDesign) {
        selectionText += ", " + this.currentBodyDesign.label;
      }

      if (this.currentTrimLevel) {
        selectionText += ", " + this.currentTrimLevel.label;
      }

      return selectionText;
    },    
  },
  components: {
    Loader,
    CarInfo,
    Sidebar,
    Stepper
  }
};
</script>

<style >
@font-face {
  font-family: 'EF-Sans';
  src: url('/EFSans-Regular.woff') format('woff');
}


body {
  font-family: "EF-Sans", Open Sans;
}

.box-color {
  background-color: #f9f9f9 !important;
}

.cd-configurator-main {
  font-family: "Open Sans", sans-serif;
}


.cd-maker .card-body {
  display: unset;
  flex-direction: unset;
}

.cd-model-label {
    min-height: 3rem;
  }

.card-body {
  display: flex;
  flex-direction: row;
  justify-content: center
}

.cd-progress {
  font-size: .9rem;
  color: #9e9e9e;
  text-transform: uppercase;
}

.cd-primary-black {
  color: #161616;
}

.cd-primary-grey {
  color: #757575;
}

.cd-selection-text {
  margin-bottom: 35px;
}

.cd-max-progress {
  font-size: .9rem;
}

.cd-primary-color {
  color: #003899 !important;
}

.cd-primary-sub-color {
  color: #161616;
}

.cd-secondary-sub-color {
  color: #757575;
}

.cd-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

h2.cd-header {
  font-size: 1.5rem;
  color: #003899;
  font-weight: bold;
}

#cd-equipment-modal .modal-title h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
}

.modal-header {
  border: none !important;
  position: relative;
}

.modal-footer {
  border: none !important;
}

.modal-header .close {
  width: 48px;
  height: 48px;
  background: #f9f9f9;
  color: #3c3c3c;
  opacity: 1;
  border-radius: 40px;
  margin: 0 !important;
}

#cd-equipment-modal .modal-content {
  border: none;
  border-radius: 20px;
}

#cd-equipment-modal .modal-footer {
  justify-content: center;
}


#cd-equipment-modal .modal-header h5 {
  font-weight: bold;
  font-size: 1.5rem;
}

.cd-accordion .card {
  border: none;
  border-bottom: 1px solid #d6d6d6;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cd-accordion .cd-equipment-list {
  padding: 0px;
  list-style: none;
}

.cd-accordion .cd-equipment-list li {
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  padding-bottom: 16px;
}

.cd-accordion .cd-accordion-header-button:hover,
.cd-accordion .cd-accordion-header-button:active,
.cd-accordion .cd-accordion-header-button:focus {
  background-color: #fff !important;
  color: #3c3c3c !important;
  box-shadow: none !important;
  border: none;
}

.cd-accordion .card-header {
  border: none;
  background-color: #fff;
}

#cd-equipment-modal .modal-footer .cd-button,
#cd-modal-equipment-compairsion .modal-footer .cd-button,
#cd-equipmentinfo-modal .modal-footer .cd-button,
#cd-equipment-selection .modal-footer .cd-button,
#cd-newconfiguration-modal .modal-footer .cd-button  {
  background-color: #003899;
  border-radius: 40px;
  font-size: .9rem;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  display: block;
  margin: 0px 62px;
  width: 100%;
  margin: 50px 55px;
}


.cd-button.cd-std-button,
.cd-button.cd-std-button:hover,
.cd-button.cd-std-button:active,
.cd-button.cd-std-button:focus {
  background-color: #003899 !important;
  border-radius: 40px;
  font-size: .9rem;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  display: block;
  width: 100%;
  border: none;
  box-shadow: none !important;
}

.cd-choose-button {
  background-color: #003899;
  border-radius: 40px;
  font-size: .9rem;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 160px;
  margin-top: 20px;
}

.cd-choose-button:hover {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

.cd-accordion .cd-accordion-header-button {
  text-align: left;
  background: #fff;
  border: none;
  color: #3c3c3c;

  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  background-repeat: no-repeat;
  background-position: right center;
}

.cd-accordion .cd-accordion-header-button.not-collapsed {
  background-image: url("./assets/images/accordion-opened.svg");
}

.cd-accordion .cd-accordion-header-button.collapsed {
  background-image: url("./assets/images/accordion-collapsed.svg");
}


/* BOX */

.cd-box-component {
  position: relative;
  min-height: 160px;
  color: #9e9e9e;
  border: none;
  border-radius: 15px;
  border: none !important;
}


.cd-box-component:hover {
  cursor: pointer;
  filter: drop-shadow(0px 10px 28px rgba(22, 22, 22, 0.12));
}

.cd-box-component img {
  border-radius: 15px;
  padding: 10px;
}

.cd-box-color {
  background-color: #f9f9f9 !important;
}



/* MAKEKRS */

.makers,
.cd-model {
  text-align: center;
}

.card.cd-maker {
  background: #f9f9f9;
  border-radius: 15px;
  padding-bottom: 2rem;
}

.cd-maker p.card-text {
  height: 100%;
}

.cd-maker .bottom-text {
  height: 100%;
}

.cd-model-image {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.cd-model-label {
  color: #161616;
  font-weight: bold;
  font-size: 1rem; 
}

.cd-model-label,
.cd-summary-container-main {
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto; 
}

.cd-original-price {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  color: #757575;
  font-size: .9rem;
}

.cd-entry.cd-model .card {
  height: auto;
  position: relative;
}

.cd-new-price {
  color: #003899;
  font-weight: bold;
  font-size: 1.2rem;
}

.cd-entry.cd-model .card {
  overflow: hidden;
}

.cd-configure-button {
  background: #003899;
  border-radius: 40px;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  display: block;
  margin-left: 10px;
  margin-right: 10px;
}

.cd-configure-hover {
  position: absolute;
  background-color: #f9f9f9;
  left: 0;
  bottom: -55px;
}



.cd-original-price {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  color: #757575;
  font-size: .9rem;
}

.cd-entry.cd-model .card {
  position: relative;
  height: 100%;  
}

.cd-entry.cd-model .card {
  overflow: hidden;
}

button.btn-hover {
  margin-top: 10px;
  background-color: #003899;
  border-radius: 40px;
  border: 1px solid #003899;
  text-transform: uppercase;
  font-size: .9rem;
  color: #fff;
  opacity: 0;
  width: 100%;
}

.card:hover button.btn-hover {
  opacity: 1;
}



.cd-entry.cd-model .card:hover .cd-configure-hover {
  bottom: 0px;
}

/** Maker images */
.maker-image {
  background-position: 50% 50% !important;
  background-size: 4em !important;
  height: 100%;
}

.img-abarth {
  background: url("./assets/images/maker/abarth.svg") no-repeat;
}

.img-aston-martin {
  background: url("./assets/images/maker/astonMartin.svg") no-repeat;
}

.img-bentley {
  background: url("./assets/images/maker/bentley.svg") no-repeat;
}

.img-cupra {
  background: url("./assets/images/maker/cupra.svg") no-repeat;
}

.img-bmw {
  background: url("./assets/images/maker/bmw.svg") no-repeat;
}

.img-bmwi {
  background: url("./assets/images/maker/bmwi.svg") no-repeat;
}

.img-fiat {
  background: url("./assets/images/maker/fiat.svg") no-repeat;
}

.img-ford {
  background: url("./assets/images/maker/ford.svg") no-repeat;
}

.img-hyundai {
  background: url("./assets/images/maker/hyundai.svg") no-repeat;
}

.img-hyundai {
  background: url("./assets/images/maker/hyundai.svg") no-repeat;
}

.img-jaguar {
  background: url("./assets/images/maker/jaguar.svg") no-repeat;
}

.img-kumpan {
  background: url("./assets/images/maker/kumpan.svg") no-repeat;
}

.img-land-rover {
  background: url("./assets/images/maker/landrover.svg") no-repeat;
}

.img-lexus {
  background: url("./assets/images/maker/lexus.svg") no-repeat;
}

.img-mazda {
  background: url("./assets/images/maker/mazda.svg") no-repeat;
}

.img-nissan {
  background: url("./assets/images/maker/nissan.svg") no-repeat;
}

.img-mercedes-benz {
  background: url("./assets/images/maker/mercedes.svg") no-repeat;
}

.img-mini {
  background: url("./assets/images/maker/mini.svg") no-repeat;
}

.img-mitsubishi {
  background: url("./assets/images/maker/mitsubishi.svg") no-repeat;
}

.img-opel {
  background: url("./assets/images/maker/opel.svg") no-repeat;
}

.img-polestar {
  background: url("./assets/images/maker/polestar.svg") no-repeat;
}

.img-seat {
  background: url("./assets/images/maker/seat.svg") no-repeat;
}

.img-skoda {
  background: url("./assets/images/maker/skoda.svg") no-repeat;
}

.img-smart {
  background: url("./assets/images/maker/smart.svg") no-repeat;
}

.img-subaru {
  background: url("./assets/images/maker/subaru.svg") no-repeat;
}

.img-toyota {
  background: url("./assets/images/maker/toyota.svg") no-repeat;
}

.img-volkswagen {
  background: url("./assets/images/maker/volkswagen.svg") no-repeat;
}

.img-volvo {
  background: url("./assets/images/maker/volvo.svg") no-repeat;
}

.img-citroën {
  background: url("./assets/images/maker/citroen.svg") no-repeat;
}

.img-peugeot {
  background: url("./assets/images/maker/peugeot.svg") no-repeat;
}

.cd-equipment-compairsion-link {
  font-style: normal;
  font-weight: bold;
  font-size: .9rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  position: absolute;
  right: 0;
  bottom: 15px;
}

.cd-center {
  text-align: center;
}

/*Icons */
.check-icon {
  background: url("./assets/images/check.svg") no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.engine-icon-motor-power {
  background: url("./assets/images/motor_power.svg") no-repeat;
}

.engine-icon-fuel-type {
  background: url("./assets/images/fuel.svg") no-repeat;
}

.engine-icon-transmission {
  background: url("./assets/images/transmission.svg") no-repeat;
}

.engine-icon-fuel-consumption {
  background: url("./assets/images/fuel_consumption.svg") no-repeat;
}

.engine-icon-emission {
  background: url("./assets/images/emission.svg") no-repeat;
}

.vs__dropdown-toggle {
  border: none !important;
  border-radius: 40px !important;
  background: #f9f9f9 !important;
  height: 48px;
}

.vs__dropdown-toggle .vs__actions {
  padding: 4px 18px 0 3px !important;
}

.vs__dropdown-toggle .vs__selected {
  padding: 0 1em;
  font-size: .9rem !important;
}


.cd-configurator-container.container {
  position: relative;
}

.cd-button-full-width {
  width: 100%!important;
  padding: 0!important;
}

.cd-configurator-main .btn-group-lg>.btn,
.cd-configurator-main .btn-lg {
  max-width: auto !important;
}

</style>
