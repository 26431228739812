<template>
  <b-container class="cd-container cd-equipment">
    <b-row>
      <b-col
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12">
        <StylizedRadioGroup
          :options="categories"
          :preSelection="selectedCategory"
          v-model="selectedCategory"
        />
      </b-col>
    </b-row>
    <b-row class="cd-equipment-cards">
      <b-col cols="12"
        v-for="e in currentCategoryEquipment"
        v-bind:key="e.id"
        sm="6"
        md="6"
        lg="3"
        xl="3"
        class="p-2 "
      >
        <b-card :class="e.selected ? 'cd-box-component cd-box-color equipment selected' : e.disabled ? 'cd-box-component cd-box-color equipment disabled' : 'cd-box-component cd-box-color equipment'" @click="toggleEquipment(e)">
          <b-card-text>
            <p class="cd-equipment-label cd-primary-black">
              {{ e.label }}
            </p>
            <p
              class="cd-equipment-price-info cd-primary-grey"
              v-if="e.price_gross"
            >
              + {{ e.price_gross | formatCurrency }}&nbsp;€
            </p>
            <a
              href="#"
              v-if="e.description && e.description.length > 0"
              @click.prevent="showEquipmentInfos(e)"
              >Mehr erfahren</a
            >
          </b-card-text>
        </b-card>
      </b-col>

    </b-row>



    <b-modal id="cd-equipmentinfo-modal" size="lg" title="">
      <template #default="{}">
        <b-container>
          <h5 class="cd-headline">Sonderausstattung</h5>
          <p class="cd-subline cd-primary-black">
            {{ currentEquipment.label }}
          </p>
          <b-col>
            <ul class="cd-equipment-info-list">
              <li v-for="(d, i) in currentEquipment.description" v-bind:key="i">
                {{ d }}
              </li>
            </ul>
          </b-col>
        </b-container>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="sm" class="cd-button" @click="ok()"
          >Hinzufügen</b-button
        >
      </template>
    </b-modal>
    <b-row class="p-4 d-flex justify-content-center">
      <b-col
        sm="12"
        md="10"
        lg="8"
        xl="6">
        <b-button
          @click="saveCarConfiguration()"
          size="sm"
          class="cd-button cd-std-button cd-next-button"
          >Weiter</b-button
        >
      </b-col>
    </b-row>
    <b-modal size="s" id="cd-equipment-modal" title="Achtung">
      <p>Bitte wählen Sie eine Farbe.</p>
      <template #modal-footer="{ ok }">
        <b-button size="sm" class="cd-button" @click="ok()"
          >Schliessen</b-button
        >
      </template>
    </b-modal>
    <b-modal id="cd-equipment-selection" size="lg" title='Bitte wählen und "Hinzufügen"' @ok="shouldSelectExtra" @hide="onCancelExtraSelection">
      <template #default="{}">
        <b-container>
          <RequirementsTree
            :level="0"
            :node="requirements"
            :checkRequirements="checkRequirements"
          />
        </b-container>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="sm" class="cd-button" @click="ok()"
          >Hinzufügen</b-button
        >
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import StylizedRadioGroup from "../elements/StyledRadioGroup.vue";
import api from "../../services/ApiService";
import { mapGetters, mapActions } from "vuex";
import store from "../../data/store";
import ExtrasService from "../../services/ExtrasService";
import lodash from "lodash";
import RequirementsTree from "../elements/RequirementsTree.vue";
import ExpressionParser from "../../lib/expressionParser";
import ExpressionHelper from "../../lib/expressionHelper";
import { PriceCalculation, createCarConfigurationObject } from '../../services/PriceCalculationService';
import TrackingService from "../../services/TrackingService";

export default {
  created() {
    this.$store.commit("setProgress", {
      progress: 6,
      title: "Ausstattung",
      subline: "Fügen Sie Ihre optionale Ausstattung hinzu.",
    });
    this.$store.commit("setShowCarInfo", true);
  },
  beforeRouteEnter(to, from, next) {
    store.commit("setShowCarInfo", false);
    store.commit("setShowEquipmentCompairsionLink", false);
    next();
  },
  mounted() {
    const vm = this;
  
    if (!this.$props.engineId) {
      const route = this.$router.currentRoute;

      return api.getMaker(route.params.maker).then((maker) => {
        vm.$store.commit("carConfiguration/setCurrentMaker", maker);
        return api.getModel(maker.id, route.params.model).then((model) => {
          vm.$store.commit("carConfiguration/setCurrentModel", model);
          return api
            .getBodyDesign(model.id, route.params.bodyDesign)
            .then((bodyDesign) => {
              vm.$store.commit(
                "carConfiguration/setCurrentBodyDesign",
                bodyDesign
              );

              return api
                .getTrimLevel(bodyDesign.id, route.params.trimLevel)
                .then((trimLevel) => {
                  vm.$store.commit(
                    "carConfiguration/setCurrentTrimLevel",
                    trimLevel
                  );

                  return api
                    .getEngine(trimLevel.id, route.params.engine)
                    .then((engine) => {
                      return api
                        .getStandardEquipments(engine.id)
                        .then((equipments) => {
                          vm.$store.commit(
                            "carConfiguration/setStandardEquipments",
                            equipments
                          );
                          vm.$store.commit(
                            "carConfiguration/setCurrentEngine",
                            engine
                          );

                          if(!vm.equipment || vm.equipment.length === 0) {
                            vm.fetchEquipment(engine.id).then(() => {
                              return vm.categorizeEquipment(vm.currentExtras);
                            });
                          }else {
                            return vm.categorizeEquipment(vm.currentExtras);
                          }
                        });
                    });
                });
            });
        });
      });
    } else {
      return vm.fetchEquipment(this.$props.engineId).then(() => {
        return vm.categorizeEquipment(vm.currentExtras);
      });
    }
  },
  /* eslint-disable no-debugger */
  methods: {
    ...mapActions({
      fetchEquipment: "equipment/fetchEquipment",
      categorizeEquipment: "equipment/categorizeEquipment",
      saveConfiguration: "carConfiguration/saveConfiguration",
    }),
    showEquipmentInfos(equipment) {
      this.currentEquipment = equipment;
      this.$bvModal.show("cd-equipmentinfo-modal");
    },
    toggleEquipment(equipment) {
      const vm = this;

      if(this.isEquipmentDisabled(equipment)) {
        return;
      }

      equipment.selected = !equipment.selected;
      this.onExtraAdded(equipment, () => {
        

        vm.$store.commit("carConfiguration/setCurrentExtras", vm.equipment);
        
        vm.saveConfiguration().then(() => {
          lodash.forEach(vm.equipment, (extra) => {            
            ExtrasService.applyNewPrice(extra, vm.equipment);
          });
        });

      });
    },
    onExtraAdded(extra, added) {
      let vm = this;

      lodash.forEach(vm.equipment, (ex) => {
        ExtrasService.applyNewPrice(ex, vm.equipment);
      });
      
      if (extra.rules.length > 0) {
        if (extra.selected) {
          var result = ExtrasService.meetsRequirements(extra, vm.equipment);

          if (result.meetsRequirements) {
            ExtrasService.applyInclusions(extra, vm.equipment);
            ExtrasService.applyExclusions(extra, vm.equipment);
            if (added) {
              added();
            }
          } else {
            this.showRequirementsModal(extra, vm.equipment, result.rule).then(addedExtras => {            
              ExtrasService.applyInclusions(extra, vm.equipment);
              ExtrasService.applyExclusions(extra, vm.equipment);
              
              let addedExtra = null;
              lodash.forEach(addedExtras, (addedExtra) => {
                let extraInListSource = lodash.filter(vm.equipment, {id: addedExtra.id})[0];

                extraInListSource.selected = true;
                extraInListSource.requiredBy = extra.id;
                extraInListSource.disabled = true;
              });

              let iterateAddedExtras = (index) => {
                if (index >= addedExtras.length) {
                  if (added) {
                    added();
                  }

                  return;
                }

                addedExtra = addedExtras[index];

                let extraInListSource = lodash.filter(vm.equipment, {id: addedExtra.id})[0];

                vm.onExtraAdded(extraInListSource, () => {
                  if (extraInListSource.selected) {
                    vm.addedExtras.push(addedExtra);

                    iterateAddedExtras(index + 1);
                  } else {
                    extraInListSource.requiredBy = '';
                    extraInListSource.disabled = false;

                    extra.selected = false;
                    ExtrasService.undoRequirements(extra, vm.equipment);
                    ExtrasService.undoInclusions(extra, vm.equipment);
                    ExtrasService.undoExclusions(extra, vm.equipment);
                  }
                });
              };

              iterateAddedExtras(0);
              vm.$forceUpdate();
            }, () => {
            
              extra.selected = false;
                            
              ExtrasService.undoRequirements(extra, vm.equipment);
              ExtrasService.undoInclusions(extra, vm.equipment);
              ExtrasService.undoExclusions(extra, vm.equipment);

              if (added) {
                added();
              }
            });
          }
        }else {
           // item was de-selected:
          ExtrasService.undoRequirements(extra, vm.equipment);
          ExtrasService.undoInclusions(extra, vm.equipment);
          ExtrasService.undoExclusions(extra, vm.equipment);
          extra.selected = false;
          if (added) {
            added();
          }
        }
      }
    },
    showRequirementsModal(extra, extras, rule) {
      let vm = this;
      this.rule = rule.rule;
      /* eslint-disable no-unused-vars */
      let p = new Promise((resolve, reject) => {
        vm.copiedExtras = JSON.parse(JSON.stringify(extras));
        vm.requirements = ExpressionParser.toExpressionTree(rule.rule, vm.copiedExtras);

        vm.$bvModal.show("cd-equipment-selection");
        vm.dialogPromiseResolve = resolve;
        vm.dialogPromiseReject = reject;
      });

      return p;
    },
    getEquipmentClass(equipment) {
      const isSelected = equipment.selected;

      let classNames = "cd-box-component cd-box-color equipment ";

      if (isSelected) {
        classNames += "selected";
      }

      if(this.isEquipmentDisabled(equipment)) {
        classNames += ' disabled';
      }

      return classNames;
    },
    saveCarConfiguration() {
      //this.$store.commit("carConfiguration/setCurrentMaker", maker);
      var vm = this;

      if (!this.hasSelectedColor) {
        this.$bvModal.show("cd-equipment-modal");
        return;
      }

      store.dispatch("loading/setLoading", true);

      this.equipment.forEach((extra) => {
        extra.price_gross = extra.price_gross ? extra.price_gross : 0;
        extra.price_net = extra.price_net ? extra.price_net : 0;
      });

      vm.$store.commit(
        "carConfiguration/setCurrentExtras",
        this.equipment
      );
      
      let calcObj = createCarConfigurationObject(this.engine, this.trimLevel, this.bodyDesign, this.model, this.extras, this.condition);
      let result = PriceCalculation.calculateDiscountedPrice(calcObj);

      vm.$store.commit('carConfiguration/setDiscountedPrice', result);
      vm.saveConfiguration().then(() => {
        TrackingService.trackStage("Step 3 - Ausstattung", `${vm.maker.label} - ${vm.model.label}`);
        store.dispatch("loading/setLoading", false);
        vm.$router.push({
          name: "summary",
          params: { configurationId: this.configurationId },
        });
      });
    },
    checkRequirements() {
      this.meetsRequirements = ExpressionHelper.meetsCondition(
        this.equipment,
        this.rule
      );
    },
    shouldSelectExtra() {
      if(this.dialogPromiseResolve) {
        this.dialogPromiseWasResolved = true;
      
        let addedExtras = ExpressionHelper.getExtras(this.rule, this.copiedExtras);
        addedExtras = lodash.filter(addedExtras, {selected: true});

        this.dialogPromiseResolve(addedExtras);
      }
    },
    onCancelExtraSelection() {      
      if(this.dialogPromiseReject && !this.dialogPromiseWasResolved) {        
        this.dialogPromiseReject();        
      }else {
        this.dialogPromiseWasResolved = false;
      }
    },
    isEquipmentSelected(equipment) {
      let isSelected = false;
      
      this.equipment.some((ce) => {
        if (ce.id === equipment.id) {
          isSelected = ce.selected;
          return true;
        }

        return false;
      });

      return isSelected;
    },
    isEquipmentDisabled(equipment) {
      return lodash.some(this.equipment, {id:equipment.id, disabled: true});
    }
  },
  computed: {
    ...mapGetters({
      categorizedEquipment: "equipment/categorizedEquipment",
      configurationId: "carConfiguration/configurationId",
      currentExtras: "carConfiguration/currentExtras",
      equipment: "equipment/equipment",
      model: 'carConfiguration/currentModel',
      bodyDesign: 'carConfiguration/currentBodyDesign',
      trimLevel: 'carConfiguration/currentTrimLevel',
      engine: 'carConfiguration/currentEngine',
      maker: 'carConfiguration/currentMaker',
      extras: 'carConfiguration/currentExtras',
      condition: 'carConfiguration/currentCondition'
    }),
    hasSelectedColor() {
      var chosenColor = lodash.filter(this.equipment, {category_name: 'Farben', selected: true})[0];
      return chosenColor != null;
    },
    currentCategoryEquipment() {
      let equipment = [];

      if (this.selectedCategory) {
        this.categorizedEquipment.some((c) => {
          if (c.category_name === this.selectedCategory) {
            equipment = c.equipment;
            return true;
          }

          return false;
        });
      }

      return equipment;
    },
  },
  watch: {
    categorizedEquipment() {
      this.categories = this.categorizedEquipment.map((c) => c.category_name);
    },
  },
  data() {
    return {
      categories: [],
      preSelection: [],
      selectedCategory: undefined,
      currentEquipment: undefined,
      selectedExtras: [],
      selectedExtrasFromDialog: [],
      requirements: undefined,
      copiedExtras: undefined,
      meetsRequirements: false,
      rule: undefined,
      dialogPromiseResolve:undefined,
      dialogPromiseReject: undefined,
      dialogPromiseWasResolved: false,
      addedExtras: []
    };
  },
  props: ["engineId"],
  components: {
    StylizedRadioGroup,
    RequirementsTree,
  },
};
</script>

<style>


.equipment .card-body {
  justify-content: start;
}

.row.cd-equipment-cards .col-3 {
  margin-bottom: 32px;
}

.row.cd-equipment-cards .card.selected {
  border: 1px solid #007bff !important;
}

.row.cd-equipment-cards .card.disabled {  
  cursor: not-allowed;
}

.row.cd-equipment-cards .card.disabled p {   
  color: lightgray;
}


.row.cd-equipment-cards .card .card-text a,
.row.cd-equipment-cards .card .card-text a:hover {
  color: #496cf6;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: .9rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.cd-equipment-label {
  font-weight: 600;
  font-size: .9rem;
  text-align: left !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cd-equipment-price-info {
  text-align: left !important;
  font-style: normal;
  font-weight: bold;
  font-size: .9rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

#cd-equipmentinfo-modal h5.cd-headline {
  font-style: normal;
  font-weight: bold;
  font-size: .9rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

#cd-equipmentinfo-modal p.cd-subline {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
}

#cd-equipmentinfo-modal .cd-equipment-info-list {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  padding-left: 15px;
  list-style: square;
}

#cd-equipmentinfo-modal .modal-body {
  padding-top: 0px;
  margin-top: 40px;
}

.cd-button.cd-std-button.cd-next-button {
  margin: auto !important;
  word-wrap:  break-word;
}


#cd-equipment-selection .entry {
  position: relative;
  background: white;
  border-radius: 4px;
  margin: 3px 0;
  padding: 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  padding-left:30px;
}

#cd-equipment-selection .entry label {
  width: 100%;
  display: block;
  padding-right: 30%;
  position: relative;

}

#cd-equipment-selection .entry input[type="checkbox"],
#cd-equipment-selection .entry input[type="radio"] {
  position: absolute;
  left: 10px;
  margin: 8px 0 0;
  top: 0;
}

#cd-equipment-selection .entry .price {
  right: 15px !important;
  top: 8px;
  position: absolute;
}

#cd-equipment-selection .entry .price span {
  font-weight: bold;
}

</style>