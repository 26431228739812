export default {
    getCo2(engine) {
        let td = engine.technical_data;

        if (td.co2_level_combined) {
            return td.co2_level_combined;
        }

        if (td.wltp_co2_level_combined) {
            return td.wltp_co2_level_combined;
        }

        if (td.wltp_co2_level_combined_high) {
            return td.wltp_co2_level_combined_high;
        }

        return "-"
    },
    getWltpConsumptionCombined: function(engine) {
        let td = engine.technical_data;
        let value = "";

        if(td.fuel_type !== 'Elektro') {
            if (td.wltp_fuel_consumption_combined_low) {
                value = td.wltp_fuel_consumption_combined_low;
            }else if (td.fuel_consumption_combined) {
                value = td.fuel_consumption_combined;
            }else if (td.wltp_fuel_consumption_combined) {
                value = td.wltp_fuel_consumption_combined;
            }
            return `${value}`;
        }
    },
    getCo2Class(engine) {
        if(engine.technical_data.wltpData) {
            let energyClass = engine.technical_data.wltpData.calculations.co2Class;
    
            return energyClass;
        }        
        return engine.technical_data.energy_efficiency;
    },
    getSecondaryCo2Class(engine) {
        if(engine.technical_data.wltpData) {
            let energyClass = engine.technical_data.wltpData.calculations.secondaryCo2Class;
    
            return energyClass;
        }        

        return null;
    },
    getCo2DepletedBattery(engine) {
        let td = engine.technical_data;
        if(td.wltpData) {        
            return td.wltpData.emissionsDepleted.combined;
        }

        return "";
    },
    getConsumption(engine) {
        return this.getWltpConsumptionCombined(engine);
        /**
        let td = engine.technical_data;

        if(td.wltpData) {        
            if(td.wltpData.carType == "combustion") {
                return td.wltpData.fuelConsumption.combined;            
            }
            if(td.wltpData.carType == "plugin-hybrid") {
                return td.wltpData.fuelConsumption.hybridMode.combined;            
            }
        }

        if(td.fuel_consumption_combined) {
            return td.fuel_consumption_combined
        }

        if(td.wltp_fuel_consumption_combined) {
            return td.wltp_fuel_consumption_combined;
        }

        if(td.wltp_co2_level_combined_high) {
            return td.wltp_co2_level_combined_high;
        }

        return "-"; */
    },
    getFuel(engine) {
        if(engine.technical_data.wltpData) {
            switch(engine.technical_data.wltpData.additionalData.secondaryFuelType) {
                case 'bleifrei':
                    return "Benzin";
                case 'Diesel':
                    return "Diesel";
            }
          }

          return "";
    },
    getPowerConsumption(engine) {
        let td = engine.technical_data;
        let calc = 0;
        if (td.power_consumption_combined_per_km) {
            calc =  td.power_consumption_combined_per_km / 10;
        }

        if (td.wltp_power_consumption_combined_per_km_high) {
            calc = td.wltp_power_consumption_combined_per_km_high / 10;
        }

        if (td.power_consumption_combined) {
            calc = td.power_consumption_combined;
        }
        
        return calc !== 0 ?`${calc}` :  "-";
    },
    getPowerConsumptionDepletedBattery(engine) {
        if(engine.technical_data.wltpData) {        
            return engine.technical_data.wltpData.fuelConsumption.nonHybridMode.combined;
        }

        return 0;
    },
    getGasConsumption(engine) {
        let td = engine.technical_data;
        if(td.gas_consumption_combined) {
            return td.gas_consumption_combined;
        }

        if(td.wltp_gas_consumption_combined) {
            return td.wltp_gas_consumption_combined;
        }

        if(td.wltp_gas_consumption_combined_high) {
            return td.wltp_gas_consumption_combined_high;            
        }

        return "-";
    },
    isPluginHybrid(engine) {
        return engine.technical_data.wltpData && engine.technical_data.wltpData.carType == "plugin-hybrid";
    },
    canCalculateCo2Class: function(engine) {
        let td = engine.technical_data;
        return td.wltp_co2_level_combined  != undefined|| td.wltp_co2_level_combined_high != undefined || td.wltp_co2_level_combined_low != undefined || td.fuel_type === "Elektro";
    },

    getWltpCo2Class: function(engine) {
        let td = engine.technical_data;
        let value = -1000;

        if(td.fuel_type !== 'Elektro') {
            if (td.wltp_co2_level_combined) {
                value = td.wltp_co2_level_combined;
            }else if (td.wltp_co2_level_combined_high) {
                value = td.wltp_co2_level_combined_high;
            }else if(td.wltp_co2_level_combined_low) {
                value = td.wltp_co2_level_combined_low;
            }

        }else {                     
            value = -10;
        }

        if(value >= -10 && value <= 0) {
            return "A";
        }

        if(value >= 1 && value <= 95) {
            return "B";
        }

        if(value >= 96 && value <= 115) {
            return "C";
        }

        if(value >= 116 && value <= 135) {
            return "D";
        }

        if(value >= 136 && value <= 155) {
            return "E";
        }

        if(value >= 156 && value <= 175) {
            return "F";
        }

        if(value >= 176 && value <= 1000) {
            return "G";
        }            

        /**
        var wltpData = engine.technical_data["wltpData"];
        
        if(wltpData.carType == "plugin-hybrid") { 
            return "(Gewichtet kombiniert | bei entladener Batterie) ("+wltpData["calculations"]["co2Class"]+" | "+wltpData["calculations"]["secondaryCo2Class"]+")";
        }
        if(wltpData.carType == "combustion") {
            return wltpData["calculations"]["co2Class"];
        }
        if(wltpData.carType == "electric") {
            return "A";
        }
         */
    }
}