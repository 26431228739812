<template>
  <b-overlay class="sidebar float-md-none float-lg-right float-xl-right">
    <div>
        <b-container >
          <b-row class="d-flex align-items-center">
            <b-col class="col-12">
              <h4 class="h5 font-weight-bold">Übersicht Ihrer Konfigur&shy;ation</h4>
            </b-col>
          </b-row>
        </b-container>

<!-- DESKTOP VERSION START -->

  <div class="d-none d-lg-block d-xl-block">
        <b-container class="overview ">
          <b-row>
            <b-col class="image-container">
                <CarImage :imageUrl="carImageUrl" class="rounded cd-car-image" />
            </b-col>
          </b-row>
           <b-row>
            <b-col>
              <h5 class="cd-model-description cd-primary-sub-color font-weight-bold">
                {{ maker ? maker.label : "" }} {{ model ? model.label : "" }}
              </h5>
              <span class="cd-engine-label">{{ engine ? engine.label : "" }}</span>
              </b-col>
            </b-row>
          </b-container>
          <b-container class="p-4">
              <b-row class="d-flex justify-content-between">
                <span>Herstellerlistenpreis</span>
                <span>{{ calculatedBasePrice[pricing] | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row class="d-flex justify-content-between">
                <span>Sonderausstattung</span>
                <span>{{ priceOfExtras | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row class="d-flex justify-content-between">
                  <span>Gesamtlistenpreis</span>
                  <span>{{ calculatedTotalPrice[pricing] | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row class="d-flex justify-content-between" v-if="calculatedSavings > 0">
                  <span>Ersparnis</span>
                  <span>{{ calculatedSavings | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row class="d-flex justify-content-between">
                  <span>Endpreis</span>
                  <span>{{ calculatedIntermediatePrice | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row class="d-flex justify-content-between" v-if="condition && condition.provision_costs">
                  <span>Bereitstellungskosten</span>
                  <span>{{ condition ? condition.provision_costs : 0| formatCurrency }}&nbsp;€</span>
              </b-row>      
            </b-container>
            <hr class="cd-divider" />
            <b-container class="p-4 d-none d-lg-block d-xl-block">
              <b-row class="d-flex justify-content-between">
                  <h3 class="cd-final-price">Unser Preis</h3>
                  <span class="cd-final-price cd-primary-color">{{ calculatedDiscountedPrice | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row>
                   <span>{{pricingHint}}</span>
              </b-row>
              </b-container>
            </div>

<!-- DESKTOP VERSION END -->          

<!-- MOBILE VERSION START -->
        <div class="d-block d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
        <b-container fluid class="mb-3 " style="background: #fff;">
          <b-row>
            <b-col class="col-2 image-container">
                <CarImage :imageUrl="carImageUrl" class="rounded cd-car-image" />
            </b-col>
            <b-col class="bestelluebersicht">
                <h5>Bestellübersicht</h5>
                <span>1 Fahrzeug</span>
            </b-col>
            <b-col class="col-2 d-flex justify-content-end p-0">
                <b-button class="toggle collapsed" v-b-toggle.collapse-1>
                  <b-icon class="when-closed" icon="arrow-down-circle-fill"></b-icon>
                  <b-icon class="when-open" icon="arrow-up-circle-fill"></b-icon>
                </b-button>
            </b-col>
           </b-row>
         </b-container>
           <b-container class="" style="background: #fff;">
             <b-row>          
              <b-col>
                <h5 class="cd-model-description cd-primary-sub-color font-weight-bold">{{ maker ? maker.label : "" }} {{ model ? model.label : "" }}</h5>
                <span class="cd-engine-label">{{ engine ? engine.label : "" }}</span>
              </b-col>
              </b-row>
            </b-container>

          <b-collapse id="collapse-1">   
            <hr class="cd-divider" />
            <b-container class="p-4" >
              <b-row class="d-flex justify-content-between">
                <span>Herstellerlistenpreis</span>
                <span>{{ calculatedBasePrice[pricing] | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row class="d-flex justify-content-between">
                <span>Sonderausstattung</span>
                <span>{{ priceOfExtras | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row class="d-flex justify-content-between">
                  <span>Gesamtlistenpreis</span>
                  <span>{{ calculatedTotalPrice[pricing] | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row class="d-flex justify-content-between" v-if="calculatedSavings > 0">
                  <span>Ersparnis</span>
                  <span>{{ calculatedSavings | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row class="d-flex justify-content-between">
                  <span>Endpreis</span>
                  <span>{{ calculatedIntermediatePrice | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row class="d-flex justify-content-between" v-if="condition && condition.provision_costs">
                  <span>Bereitstellungskosten</span>
                  <span>{{ condition ? condition.provision_costs : 0| formatCurrency }}&nbsp;€</span>
              </b-row>      
            </b-container>
            <hr class="cd-divider" />
            <b-container class="p-4">
              <b-row class="d-flex justify-content-between">
                  <h3 class="cd-final-price">Unser Preis</h3>
                  <span class="cd-final-price cd-primary-color">{{ calculatedDiscountedPrice | formatCurrency }}&nbsp;€</span>
              </b-row>
              <b-row>
                   <span>{{pricingHint}}</span>
              </b-row>
              </b-container>
          </b-collapse>
        </div>

<!-- MOBILE VERSION END -->


            <hr class="cd-divider" />
 
        <b-container p-2 class="cd-price-info d-none d-sm-none d-md-none d-lg-block">
          <b-container p-2>
                <b-row>
                  <b-col>
                 <SidebarButton />
               </b-col>
             </b-row>
          </b-container>

          <b-container>
              <b-row class="cd-offer-container">
                <b-col class="text-center">
                  <!--
                  <a
                    href="#"
                    class="cd-offer-link"
                    v-if="showOfferLink || contactData"
                    @click.prevent="downloadOfferPdf()"
                    >Angebot drucken</a>-->
                </b-col>
              </b-row>
          </b-container>

        </b-container>
    </div>
  </b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import imageService from "../../services/ImageService";
import ApiClient from "../../services/ApiService";
import CarImage from "../elements/CarImage.vue";
import {PriceCalculation, createCarConfigurationObject} from '../../services/PriceCalculationService'
import lodash from 'lodash';
import config from '../../config'
import SidebarButton from './SidebarButton.vue';

export default {
  created() {},

  data() {
    return {
      priceSwitchItems: ['Netto', 'Brutto'],
      selectedPriceType: 'Brutto',      
    };
  },
  computed: {
    ...mapGetters({
      externalId: "carConfiguration/configurationId",
      maker: "carConfiguration/currentMaker",
      model: "carConfiguration/currentModel",
      bodyDesign: "carConfiguration/currentBodyDesign",
      trimLevel: "carConfiguration/currentTrimLevel",
      engine: "carConfiguration/currentEngine",
      extras: "carConfiguration/currentExtras",
      currentProgress: "currentProgress",
      contactData: "carConfiguration/currentContactData",
      showOfferLink: "showOfferLink",
      offerSubmitted: "offerSubmitted",
      condition: "carConfiguration/currentCondition"

    }),
    priceConfig() {
      return this.selectedPriceType === 'Netto' ? 'net' : 'gross'
    },
    pricingHint() {
      if(this.priceConfig === 'net') {
        return "Alle Preise zzgl. aktuell gültiger MwSt.";
      }

      return "Alle Preise inkl. aktuell gültiger MwSt.";
    },
    submitButtonClasses() {
      let classes =
        "cd-button cd-std-button cd-next-button cd-button-full-width";

      if (this.offerSubmitted) {
        classes += " cd-disabled-button";
      }

      return classes;
    },
    selectedColor() {
      let color = undefined;

      this.extras.some((e) => {
        if (e.option_type === "C" && e.category_name === "Farben" && e.selected) {
          color = e;
          return true;
        }

        return false;
      });

      return color;
    },
    pricing() {
      return config.isLeasingConfigurator ? this.priceConfig : config.pricing;
    },
    priceOfExtras() {
      let value = 0;
      let selectedExtras = lodash.filter(this.extras, {selected: true});
      selectedExtras.forEach((e) => {
        if (e.price_gross) {
          value += e.price_gross;
        }
      });

      return value;
    },
    carImageUrl() {
        let value = this.getCarImage(this.trimLevel, this.selectedColor);

        return value;
    },
    calculatedBasePrice() { 
      let carConfigurationObj = createCarConfigurationObject(this.engine, this.trimLevel, this.bodyDesign, this.model, this.extras, this.condition);
      return PriceCalculation.calculateBasePrice(carConfigurationObj);
      //return this.engine ? this.engine.min_price_gross + this.priceOfExtras : 0;
    },
    calculatedTotalPrice() {
      let carConfigurationObj = createCarConfigurationObject(this.engine, this.trimLevel, this.bodyDesign, this.model, this.extras, this.condition);
      return PriceCalculation.calculateTotalPrice(carConfigurationObj);
    },
    calculatedDiscountedPrice() {
      /*debugger;*/
      let carConfigurationObj = createCarConfigurationObject(this.engine, this.trimLevel, this.bodyDesign, this.model, this.extras, this.condition);
      const result = PriceCalculation.calculateDiscountedPrice(carConfigurationObj);
      return result[this.pricing] ? result[this.pricing].finalPrice : 0;
    },
    calculatedSavings() {
      let carConfigurationObj = createCarConfigurationObject(this.engine, this.trimLevel, this.bodyDesign, this.model, this.extras, this.condition);
      const result = PriceCalculation.calculateDiscountedPrice(carConfigurationObj);
      return result[this.pricing] ? result[this.pricing].savings : 0;      
    },
    calculatedIntermediatePrice() {
      
      let carConfigurationObj = createCarConfigurationObject(this.engine, this.trimLevel, this.bodyDesign, this.model, this.extras, this.condition);
      const result = PriceCalculation.calculateDiscountedPrice(carConfigurationObj);
      return result[this.pricing] ? result[this.pricing].intermediatePrice : 0;      
    }
  },
  methods: {
    getCarImage(engine, selectedColor) {
      return imageService.buildImageUrlForPerspective(engine, 0, selectedColor, this.maker, this.model);
    },    
    downloadOfferPdf() {
      return ApiClient.getOfferPdf(this.externalId).then((pdfContent) => {
        /*debugger;*/
        const blob = new Blob([pdfContent], { type: "application/pdf" });
        const link = document.createElement("a");

        link.href = URL.createObjectURL(blob);
        link.download = "Angebot.pdf";
        link.click();

        URL.revokeObjectURL(blob);
        document.removeChild(link);
      });
    },
  },
  components: {
    CarImage,
    SidebarButton,
  }
};
</script>

<style>

.bestelluebersicht h5 {
  margin-bottom: 0px;
}  

.sidebar span {
  color:  #757575;
}

button.toggle,
button.toggle:active,
button.toggle:hover {
  background-color: unset !important;
  border: 0;
  box-shadow: none !important;
}

.b-icon.bi {
  color: #69D2AD;
}  

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none !important;
}

.sidebar {
  background: #f9f9f9;
  padding: .5rem;
  margin: auto;
  z-index: 99;
}

.image-container {
  width: 100%;
  text-align: center;
}

.image-container img {
  width: 100%;
}

.cd-offer-link {
  font-style: normal;
  font-weight: bold;
  font-size: .9rem;
  letter-spacing: .1rem;
  text-transform: uppercase;
  color: #496cf6;
  margin: auto;
  margin-top:16px;
}

.cd-sidebar-headline {
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.cd-sidebar-buttons {
  z-index: 99;
}

.cd-car-info {
  width: 100%;
}

.cd-price-subnote {
  font-style: normal;
  font-weight: normal;
  font-size: 9.rem;
  color: #757575;
}

.cd-sidebar .cd-model-description {
  font-style: normal;
  font-weight: bold;
  font-size: 1.025;
}

.cd-car-image {
  margin-bottom: 16px;
}

.cd-engine-label {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  padding-bottom: 24px;
}

.cd-divider {
  border-top: 1px solid #d6d6d6;
}

.cd-price-description,
.cd-price-value {
  font-style: normal;
  font-weight: normal;
  font-size: .9rem;
  color: #757575;
}

.cd-price-value {
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 9.rem;
  text-align: right;
}

.cd-final-price,
.cd-final-price-value {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
}

.cd-final-price-value {
  text-align: right;
}

.cd-price-switch fieldset{
  margin-top:5px;
}

.cd-button.cd-std-button.cd-disabled-button,
.cd-button.cd-std-button.cd-disabled-button:hover,
.cd-button.cd-std-button.cd-disabled-button:active,
.cd-button.cd-std-button.cd-disabled-button:focus {
  background-color: #b6b6b6!important;
}

</style>
