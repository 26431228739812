<template>
    <div class="text-center" v-show="isLoading">
  <b-spinner class="spinner" variant="primary" label="Spinning"></b-spinner>
</div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            isLoading: 'loading/isLoading'
        })
    }
}
</script>


<style scoped>

.spinner {
  width: 5rem;
  height: 5rem;
  }
</style>