<template>
  <b-form-group v-slot="{ ariaDescribedby }">
    <b-form-radio-group
      id="btn-radios-2"
      v-model="selection"
      :options="options"
      :aria-describedby="ariaDescribedby"
      size="lg"
      buttons
      class="scroll align-items-center"
    ></b-form-radio-group>
  </b-form-group>
</template>

<script>
/* eslint-disable no-debugger */
export default {
  created() {},
  computed: {
    selection: {
      get() {
        return this.selectedItem;
      },
      set(value) {
        this.selectedItem = value;
        this.$emit("input", value);
      },
    },
  },
  watch: {
    options() {
      if (!this.selectedItem) {
        this.selectedItem = this.options[0];
      }
    },
  },
  data() {
    return {
      selectedItem: this.preSelection,
    };
  },
  props: ["options", "preSelection"],
};
</script>

<style>
.cd-engines .btn-group-toggle,
.cd-equipment .btn-group-toggle {
  max-width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 100px;
  padding: 2px;
  overflow-y: hidden;
  white-space: nowrap;
}

.cd-engines .btn-group-toggle label,
.cd-equipment .btn-group-toggle label {
  padding: .5rem .5rem;
}

.btn-group label.btn-lg {
  min-width: fit-content;
}

.cd-engines .btn-group-toggle label.active,
.cd-equipment .btn-group-toggle label.active {
  color: #003899 !important;
  background: #fff !important;
  /*box-shadow: 0px 10px 28px rgba(22, 22, 22, 0.12);*/
}

.cd-engines .btn-group-toggle label,
.cd-engines .btn-group-toggle label:active,
.cd-equipment .btn-group-toggle label,
.cd-equipment .btn-group-toggle label:active {
  background-color: #ffffff !important;
  border-radius: 32px !important;
  border: none !important;
  color: #757575 !important;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 3em;
}

.scroll
{
  overflow-y: auto;
  }

.scroll::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 100px;
  background-color: #F5F5F5;
}

.scroll::-webkit-scrollbar
{
  width: 12px;
  background-color: #F5F5F5;
}

.scroll::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #003899;
}

* {
    scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: #003899 #F5F5F5;   /* scroll thumb and track */
}

</style>
