<template>
  <b-container class="cd-container cd-engines">
    <b-row>
      <b-col cols="12">
        <StylizedRadioGroup
          :options="engineFilters"
          :preSelection="selectedEngineFilter"
          v-model="selectedEngineFilter"
        />
      </b-col>
    </b-row>
    <b-row
      v-for="category in categorizedEngines"
      v-bind:key="category.category"
      class="cd-engine-category-row"
    >
      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
        <span class="cd-engine-category">{{ category.category }}</span>
      </b-col>

      <b-col
        xs="12"
        sm="12"
        md="9"
        lg="9"
        xl="9"
        class="cd-engine-representation"
      >
        <b-card
          v-for="engine in category.engines"
          v-bind:key="engine.id"
          @click.prevent="selectEngine(engine)"
        >
          <b-row>
            <b-col class="columns-left" xs="12" sm="12" md="6" lg="8" xl="8">
              <h5>
                {{ engine.label }}
              </h5>
              <p class="cd-engine-energy-efficiency cd-secondary-sub-color">
                <span v-if="canCalculateCo2Class(engine)">
                  CO<sub>2</sub>-Klasse:
                </span>
                <span v-if="!canCalculateCo2Class(engine)">
                  Energieeffizienzklasse:
                </span>
                <span :id="'engine-target-' + engine.id">{{getCo2Class(engine)}}</span>
                <span v-if="hasSecondaryCo2Class(engine)">{{ ' | '+secondaryCo2Class(engine) }}</span>
              </p>
              <b-popover
                :target="'engine-target-' + engine.id"
                triggers="hover"
              >
                <EnergyEfficiencyBox
                  :energyClass="engine.technical_data.energy_efficiency"
                />
              </b-popover>
              <div class="cd-engine-info d-flex flex-wrap">
                <IconLabel
                  iconClass="engine-icon-motor-power"
                  :value="
                    engine.technical_data.max_power_kw +
                    'kW (' +
                    engine.technical_data.max_power_ps +
                    ' PS)'
                  "
                />
                <IconLabel
                  iconClass="engine-icon-fuel-type"
                  :value="engine.fuel_type"
                />
                <IconLabel
                  iconClass="engine-icon-transmission"
                  :value="engine.transmission"
                />
                <IconLabel
                  v-if="(engine.fuel_type != 'Elektro' || (engine.fuel_type === 'Elektro' && (getCo2(engine) != '-' && getCo2(engine) != '0' && getCo2(engine) != '0.0')))"
                  iconClass="engine-icon-fuel-consumption"
                  :value="(engine.fuel_type == 'Wasserstoff' || engine.fuel_type == 'Erdgas') ? getGasConsumption(engine) + 'kg/100km' : getFuelConsumption(engine) + 'l/100km'"
                />
                <IconLabel
                  v-if="engine.fuel_type == 'Elektro' && !isPluginHybrid(engine)"
                  iconClass="engine-icon-fuel-consumption"
                  :value="getPowerConsumption(engine) + 'kWh/100km'"
                />
                <IconLabel
                  v-if="isPluginHybrid(engine)"
                  iconClass="engine-icon-fuel-consumption"
                  :value="'Kombiniert gewichtet:<br/>'+getPowerConsumption(engine) + 'kWh/100km' + getPluginHybridAddition(engine)"
                />
            
                <IconLabel
                  v-if="isPluginHybrid(engine)"
                  iconClass="engine-icon-fuel-consumption"
                  :value="'Bei entladener Batterie kombiniert:<br/>'+getPowerConsumptionBatteryDepleted(engine) + 'l/100km'"
                />
                <IconLabel
                  v-if="!isPluginHybrid(engine)"
                  iconClass="engine-icon-emission"
                  :value="getCo2(engine) + ' g/km'"
                >
                  <span
                    class="cd-efficiency-mark"                    
                    :id="'energyefficiency-target-' + engine.id"
                  >
                    <span
                      :class="
                        'mark-background ' +
                        calculateEnergyClass(
                          engine
                        )
                      "
                    ></span>
                    <span class="cd-efficiency-marklabel">{{
                      getCo2Class(engine)                      
                    }}</span>
                    
                    <span v-if="hasSecondaryCo2Class(engine)"
                      :class="'mark-background secondary ' +calculateSecondaryEnergyClass(engine)"
                    ></span>
                    <span v-if="hasSecondaryCo2Class(engine)" class="cd-efficiency-marklabel secondary">{{
                      secondaryCo2Class(engine)
                    }}</span>

                    <b-popover
                      :target="'energyefficiency-target-' + engine.id"
                      triggers="hover"
                    >
                      <EnergyEfficiencyBox                        
                        :engine="engine"
                      />
                    </b-popover>
                  </span>
                </IconLabel>
                <IconLabel
                  v-if="isPluginHybrid(engine)"
                  iconClass="engine-icon-emission"
                  :value="'Gewichtet kombiniert: '+getCo2(engine) + 'g/km<br/>Bei entladener Batterie kombiniert: '+getCo2DepletedBattery(engine) + 'g/km'"
                >
                  <span
                    class="cd-efficiency-mark"
                    v-if="engine.technical_data.energy_efficiency || engine.technical_data.wltpData"
                    :id="'energyefficiency-target-' + engine.id"
                  >
                    <span
                      :class="
                        'mark-background ' +
                        calculateEnergyClass(
                          engine
                        )
                      "
                    ></span>
                    <span class="cd-efficiency-marklabel">{{getCo2Class(engine)}}</span>
                    
                    <span v-if="hasSecondaryCo2Class(engine)"
                      :class="'mark-background secondary ' +calculateSecondaryEnergyClass(engine)"
                    ></span>
                    <span v-if="hasSecondaryCo2Class(engine)" class="cd-efficiency-marklabel secondary">{{
                      secondaryCo2Class(engine)
                    }}</span>

                    <b-popover
                      :target="'energyefficiency-target-' + engine.id"
                      triggers="hover"
                    >
                      <EnergyEfficiencyBox                        
                        :engine="engine"
                      />
                    </b-popover>
                  </span>
                </IconLabel>
                <div v-if="!isPluginHybrid(engine)"><br/>Alle Verbrauchs- und Emissions-Angaben sind kombinierte Werte.</div>
              </div>
            </b-col>

            <b-col class="columns-right" xs="12" sm="12" md="6" lg="4" xl="4">
              <span class="cd-original-price"
                >uvp ab {{ engine.min_price_gross | formatCurrency }}&nbsp;€</span
              >
              <p class="cd-new-price">
                ab
                {{
                  getNewPrice(engine["min_price_" + pricing], engine)
                    | formatCurrency
                }}
              &nbsp;€
              </p>
              <button type="button" class="btn btn-primary btn-hover">
                Auswählen
              </button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from "../../services/ApiService";
import { mapActions, mapGetters } from "vuex";
import IconLabel from "../elements/IconLabel";
import StylizedRadioGroup from "../elements/StyledRadioGroup.vue";
import EnergyEfficiencyBox from "../elements/EnergyEfficiencyBox.vue";
import store from "../../data/store";
import ApiService from "../../services/ApiService";
import ConditionsService from "../../services/ConditionsService";
import config from "../../config";
import valueService from '../../services/ValueService';
import ValueService from '../../services/ValueService';

export default {
  created() {
    this.$store.commit("setProgress", {
      progress: 5,
      title: "Motor",
      subline: "Wählen Sie einen Motor aus.",
    });
  },
  beforeRouteEnter(to, from, next) {
    store.commit("setShowCarInfo", false);
    store.commit("setShowEquipmentCompairsionLink", false);
    next();
  },
  mounted() {
    ConditionsService.initialize(this.conditions);

    this.$store.commit("setShowCarInfo", false);
    const vm = this;

    if (!this.$props.trimLevelId) {
      const route = this.$router.currentRoute;

      return api.getMaker(route.params.maker).then((maker) => {
        vm.$store.commit("carConfiguration/setCurrentMaker", maker);
        return api.getModel(maker.id, route.params.model).then((model) => {
          vm.$store.commit("carConfiguration/setCurrentModel", model);
          return api
            .getBodyDesign(model.id, route.params.bodyDesign)
            .then((bodyDesign) => {
              vm.$store.commit(
                "carConfiguration/setCurrentBodyDesign",
                bodyDesign
              );

              return api
                .getTrimLevel(bodyDesign.id, route.params.trimLevel)
                .then((trimLevel) => {
                  vm.$store.commit(
                    "carConfiguration/setCurrentTrimLevel",
                    trimLevel
                  );
                  vm.fetchEngines(trimLevel.id).then(() => {
                    vm.categorizeEnginesBy(vm.selectedEngineFilter);
                  });
                });
            });
        });
      });
    } else {
      vm.fetchEngines(this.$props.trimLevelId).then(() => {
        vm.categorizeEnginesBy(vm.selectedEngineFilter);
      });
    }
  },
  computed: {
    ...mapGetters({
      categorizedEngines: "engines/filteredEngines",
      currentMaker: "carConfiguration/currentMaker",
      currentModel: "carConfiguration/currentModel",
      currentBodyDesign: "carConfiguration/currentBodyDesign",
      currentTrimLevel: "carConfiguration/currentTrimLevel",
      conditions: "conditions/conditions",
    }),
    pricing() {
      return config.pricing;
    },

  },
  methods: {
    ...mapActions({
      fetchEngines: "engines/fetchEngines",
      categorizeEnginesBy: "engines/categorizeEnginesBy",
    }),
    getCo2(engine) {
      return valueService.getCo2(engine);
    },
    getCo2DepletedBattery(engine) {
      return valueService.getCo2DepletedBattery(engine);
    },
    getFuel(engine) {
      return valueService.getFuel(engine);
    },
    getCo2Class(engine) {
      return valueService.getWltpCo2Class(engine);    
    },
    getPluginHybridAddition(engine) {
      if(engine.technical_data.wltpData) {
        if(engine.technical_data.wltpData.carType == "plugin-hybrid") {
          return ` plus ${engine.technical_data.wltpData.fuelConsumption.hybridMode.combined}l ${this.getFuel(engine)}/100km`
        }
      }

      return "";
    },
    getFuelConsumption(engine) {
      return valueService.getConsumption(engine);
    },
    getPowerConsumption(engine) {
      return valueService.getPowerConsumption(engine);
    },
    getPowerConsumptionBatteryDepleted(engine) {
      return valueService.getPowerConsumptionDepletedBattery(engine);
    },
    getGasConsumption(engine) {
      return valueService.getGasConsumption(engine);
    },
    getNewPrice(oldPrice, data) {
      if (!this.conditions.size) {
        return oldPrice;
      }
      return ConditionsService.getNewPrice(oldPrice, data);
    },
    canCalculateCo2Class(engine) {
      return ValueService.canCalculateCo2Class(engine);
    },
    calculateEnergyClass(engine) {

      if (!engine) {
        return "-";
      }

      let energyClass = engine.technical_data.energy_efficiency;
      let cssClass = "efficiency-";

      if(valueService.canCalculateCo2Class(engine)) {
        energyClass = valueService.getWltpCo2Class(engine);
        cssClass = `${cssClass}wltp-`
      }

      let c = energyClass.toLowerCase();
      
      c = c.replace(/\+/g, "p");

      return cssClass + c;
    },
    calculateSecondaryEnergyClass(engine) {
      if(engine.technical_data.wltpData) {
        let energyClass = engine.technical_data.wltpData.calculations.secondaryCo2Class.toLowerCase();
        let cssClass = "efficiency-wltp-";

        return cssClass+energyClass;
      }

      return "";
    },
    secondaryCo2Class(engine) {
      if(engine.technical_data.wltpData && engine.technical_data.wltpData.calculations.secondaryCo2Class) {
        return engine.technical_data.wltpData.calculations.secondaryCo2Class;
      }

      return "";
    },
    hasSecondaryCo2Class(engine) {
      return engine.technical_data.wltpData && engine.technical_data.wltpData.calculations.secondaryCo2Class;
    },
    isPluginHybrid(engine) {
      return engine.technical_data.wltpData && engine.technical_data.wltpData.carType == "plugin-hybrid";
    },
    selectEngine(engine) {
      let vm = this;
      this.$store.commit("carConfiguration/setCurrentEngine", engine);
      this.$store.commit(
        "carConfiguration/setCurrentCondition",
        ConditionsService.getConditions(engine)
      );
      this.$store.commit("equipment/setEquipment", []);
      this.$store.commit("setCurrentExtras/currentExtras", []);
      ApiService.getStandardEquipments(engine.id).then((equipments) => {
        vm.$store.commit("carConfiguration/setStandardEquipments", equipments);
        this.$router.push({
          name: "equipment",
          params: {
            maker: this.currentMaker.label,
            model: this.currentModel.label,
            bodyDesign: this.currentBodyDesign.label,
            trimLevel: this.currentTrimLevel.label,
            engine: engine.label,
            engineId: engine.id,
          },
        });
      });
    },
  },
  watch: {
    conditions(val) {
      ConditionsService.initialize(val);
    },
    selectedEngineFilter() {
      this.categorizeEnginesBy(this.selectedEngineFilter);
    },
  },
  props: ["trimLevelId"],
  data() {
    return {
      engineFilters: [
        { text: "Kraftstoff", value: "fuel_type" },
        { text: "Antrieb", value: "drive" },
        { text: "Getriebe", value: "transmission" },
      ],
      selectedEngineFilter: "fuel_type",
      showEnergyEficiencyPopup: false,
      currentEnergyEficiencyClass: "",
    };
  },
  components: {
    IconLabel,
    StylizedRadioGroup,
    EnergyEfficiencyBox,
  },
};
</script>

<style>
.cd-engine-category {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
}

.columns-right {
  text-align: right;
  padding: 20px;
}

[class^="engine-icon"] {
  padding-left: 30px;
  fill: #fff;
}

.cd-engine-representation .card {
  background-color: #f9f9f9;
  border: none;
  padding: 0;
  border-radius: 15px;
  margin-bottom: 25px;
}

.cd-engine-representation .card:hover {
  cursor: pointer;
  box-shadow: 0px 10px 28px rgba(22, 22, 22, 0.12);
}

.cd-engine-representation .card:last-child {
  margin-bottom: 0px;
}

.cd-engine-representation .card .card-body {
  padding: 32px;
}

.cd-engine-representation .card .card-body h5.engine-label {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
}

.cd-engine-representation .card .card-body .cd-engine-energy-efficiency {
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
}

.cd-engine-info span {
  margin-right: 30px;
}

.cd-engine-category-row {
  padding: 64px 0px;
  border-bottom: 1px solid #d6d6d6;
}

.popover {
  max-width: 400px !important;
}


.break {
  flex-basis: 100%;
  height: 0;
}
</style>