<template>
  <b-container class="cd-container">
    <b-row>
     <b-col cols="12"
        class="cd-entry cd-trim-level p-2"
        v-bind:key="trimLevel.id"
        v-for="trimLevel in extendedTrimLevels"
        sm="6"
        md="6"
        lg="3"
        xl="3"
        @click.prevent="selectTrimLevel(trimLevel)"
      >
        <Box class="cd-primary-sub-color">
          <span class="cd-trimlevel-label">{{ trimLevel.label }}</span>
          <ul class="cd-trim-level-list">
            <li
              v-for="highlight in trimLevel.equipmentHighlights"
              v-bind:key="highlight"
            >
              {{ highlight }}
            </li>
          </ul>
          <p class="more-link-container">
            <a @click.stop="showEquipment(trimLevel)" class="more-link"
              >mehr erfahren</a
            >
          </p>

          <div class="cd-trim-level-price">
            <span class="cd-original-price"
              >uvp ab {{ trimLevel.min_price_gross | formatCurrency }} €</span
            >
            <span class="cd-new-price"
              >ab {{ getNewPrice(trimLevel['min_price_'+pricing], trimLevel) | formatCurrency }}&nbsp;€</span>
          </div>
          <button type="button" class="btn btn-primary btn-hover">jetzt konfigurieren</button>          
        </Box>
      </b-col>
    </b-row>
    <b-modal
      id="cd-modal-equipment-compairsion"
      title="Ausstattungsvergleich"
      size="xl"
    >
      <template #default="{}">
        <b-container class="cd-equipment-summary-header">
          <b-row>
            <b-col col-md-6 col-12>
              <CarImage :imageUrl="getCarImage(currentModel)" />
            </b-col>
            <b-col>
              <p class="cd-equipment-summary-first">
                {{ currentMaker.label }}</p>
              <p class="cd-equipment-summary-second">
                {{ currentModel.label }}</p>
              <p class="cd-equipment-summary-second">
                {{ currentBodyDesign.label }}
              </p>
            </b-col>
          </b-row>
        </b-container>
        <b-container>
          <b-row cols="12">
            <b-col cols="12">
              <p class="cd-compairsion-selection-hint">
                Bitte wählen Sie 2 verschiedene Ausstattungslinien.
              </p>
              <b-form-checkbox
                id="difference-only"
                v-model="showDifference"
                name="difference-only"
                >nur Unterschiede anzeigen</b-form-checkbox
              >
            </b-col>
            <b-col xs="12" md="6" >
              <v-select
                label="label"
                placeholder="Modell 1"
                :options="extendedTrimLevels"
                v-model="firstSelectedTrimLevel"
              ></v-select>
            </b-col>
            <b-col>
              <v-select
                label="label"
                placeholder="Modell 2"
                :options="extendedTrimLevels"
                v-model="secondSelectedTrimLevel"
              ></v-select>
            </b-col>
          </b-row>
        </b-container>
        <b-container class="pt-4 p-0">
          <div class="cd-accordion" role="tablist">
            <b-card style="padding: 0; !important"
              no-body
              class=""
              v-for="(category, categoryIndex) in equipmentCompairsion"
              v-bind:key="category.category"
            >
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button
                  block
                  v-b-toggle="'equipment-category-' + categoryIndex"
                  class="cd-accordion-header-button"
                  >{{ category.category }}</b-button
                >
              </b-card-header>
              <b-collapse
                :id="'equipment-category-' + categoryIndex"
                visible
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body class="p-0">
                  <b-container>
                    <b-row
                      class="cd-equipment-row"
                      v-for="(
                        equipment, equipmentIndex
                      ) in category.equipmentList"
                      v-bind:key="equipmentIndex"
                    >
                      <b-col cols="6">{{ equipment.title }}</b-col>
                      <b-col class="cd-center"
                        ><i class="check-icon" v-if="equipment.cars[0]"
                      /></b-col>
                      <b-col class="cd-center"
                        ><i class="check-icon" v-if="equipment.cars[1]"
                      /></b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-container>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="sm" class="cd-button" @click="ok()"
          >Schliessen</b-button
        >
      </template>
    </b-modal>
    <b-modal id="cd-equipment-modal" size="lg" v-bind:title="equipmentTitle">
      <template #default="{}">
        <b-container class="cd-equipment-summary-header">
          <b-row>
            <b-col cols="12" md="6">
              <CarImage :imageUrl="getCarImage(currentModel)" />
            </b-col>
            <b-col>
              <p class="cd-equipment-summary-first">
                {{ currentMaker.label }}</p>
              <p class="cd-equipment-summary-second">
                {{ currentModel.label }}</p>
              <p class="cd-equipment-summary-second">
                {{ currentBodyDesign.label }}
              </p>
            </b-col>
          </b-row>
        </b-container>
        <div class="cd-accordion" role="tablist">
          <b-card
            no-body
            class="mb-1"
            v-for="(
              equipmentCategory, categoryIndex
            ) in selectedTrimLevel.equipmentList"
            v-bind:key="equipmentCategory.category"
          >
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle="'equipment-category-' + categoryIndex"
                class="cd-accordion-header-button"
                >{{ equipmentCategory.category }}</b-button
              >
            </b-card-header>
            <b-collapse
              :id="'equipment-category-' + categoryIndex"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <ul class="cd-equipment-list">
                  <li
                    v-for="(
                      equipmentLabel, equipmentIndex
                    ) in equipmentCategory.equipmentEntries"
                    v-bind:key="equipmentIndex"
                  >
                    {{ equipmentLabel }}
                  </li>
                </ul>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="sm" class="cd-button" @click="ok()"
          >Schliessen</b-button
        >
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import Box from "../elements/Box";
import { mapGetters, mapActions } from "vuex";
import api from "../../services/ApiService";
import imageService from "../../services/ImageService";
import eventBus from "../../eventing/eventBus";
import CarImage from '../elements/CarImage.vue';
import ConditionsService from "../../services/ConditionsService";
import config from "../../config";
import lodash from 'lodash';

/* eslint-disable no-debugger */
export default {
  created() {
    this.$store.commit("setProgress", {
      progress: 4,
      title: "Ausstattungslinie",
      subline: "Wählen Sie eine Ausstattungslinie.",
    });
    this.$store.commit("setShowEquipmentCompairsionLink", true);
    this.$store.commit("setShowCarInfo", false);
  },
  mounted() {
    ConditionsService.initialize(this.conditions);

    let vm = this;

    eventBus.$on("open-equipment-compairsion", () => {
      vm.openEquipmentCompairsion();
    });

    if (!this.$props.bodyDesignId) {
      const route = this.$router.currentRoute;

      return api.getMaker(route.params.maker).then((maker) => {
        vm.$store.commit("carConfiguration/setCurrentMaker", maker);
        return api.getModel(maker.id, route.params.model).then((model) => {
          vm.$store.commit("carConfiguration/setCurrentModel", model);
          return api
            .getBodyDesign(model.id, route.params.bodyDesign)
            .then((bodyDesign) => {
              vm.$store.commit(
                "carConfiguration/setCurrentBodyDesign",
                bodyDesign
              );

              return vm.fetchTrimLevels(bodyDesign.id).then(() => {
                const engine = this.getEngineFromTrimLevel(vm.trimLevels[0]);
                return api.getCompairsion(engine).then((compairsion) => {
                  vm.compairsion = compairsion;
                  vm.updateTrimLevels();
                });
              });
            });
        });
      });
    } else {
      const vm = this;

      return vm.fetchTrimLevels(this.$props.bodyDesignId).then(() => {
        const engine = this.getEngineFromTrimLevel(vm.trimLevels[0]);

        return api.getCompairsion(engine).then((compairsion) => {
          vm.compairsion = compairsion;
          vm.updateTrimLevels();
        });
      });
    }
  },

  methods: {
    ...mapActions({
      fetchTrimLevels: "trimLevels/fetchTrimLevels",      
    }),
    getNewPrice(oldPrice, data) {
      if (!this.conditions.size) {
        return oldPrice;
      }
      return ConditionsService.getNewPrice(oldPrice, data);
    },
    updateTrimLevels() {
      const vm = this;

      if (this.trimLevels) {
        this.trimLevels.forEach((t, i) => {
          let currentTrimLevel = t;
          currentTrimLevel.equipmentHighlights = vm.getEquipmentHighlights(i);
          currentTrimLevel.equipmentList = vm.getEquipment(i);
          vm.extendedTrimLevels.push(currentTrimLevel);
        });

        vm.extendedTrimLevels = lodash.orderBy(vm.extendedTrimLevels, ['min_price_gross'], ['asc']);
      }      
    },
    showEquipment(trimLevel) {
      this.selectedTrimLevel = trimLevel;
      this.$bvModal.show("cd-equipment-modal");
    },
    getEquipmentHighlights(currentIndex) {
      let equipment = this.compairsion.equipment;
      let equipmentHighlights = [];
      let includedEquipment = [];

      //Differences
      for (let equipmentKey in equipment) {
        if (currentIndex == 0) {
          if (equipment[equipmentKey].cars[currentIndex]) {
            equipmentHighlights.push(equipmentKey);
          }
        } else {
          if (equipment[equipmentKey].cars[currentIndex]) {
            includedEquipment.push(equipmentKey);
            if (!equipment[equipmentKey].cars[currentIndex - 1]) {
              equipmentHighlights.push(equipmentKey);
            }
          }
        }

        if (equipmentHighlights.length == 4) {
          break;
        }
      }

      // Fillup if
      if (equipmentHighlights.length < 4) {
        for (let eq in includedEquipment) {
          if (!equipmentHighlights.includes(includedEquipment[eq])) {
            equipmentHighlights.push(includedEquipment[eq]);
          }

          if (equipmentHighlights.length === 4) {
            break;
          }
        }
      }
      return equipmentHighlights;
    },
    getEquipment(currentIndex) {
      let equipment = this.compairsion.equipment;
      let categories = [];

      //Differences
      for (let equipmentKey in equipment) {
        if (equipment[equipmentKey].cars[currentIndex]) {
          const categoryExists = categories.some((c) => {
            return c.category === equipment[equipmentKey].category_name;
          });

          if (!categoryExists) {
            categories.push({
              category: equipment[equipmentKey].category_name,
              equipmentEntries: [equipmentKey],
            });
          } else {
            categories.some((c) => {
              if (c.category === equipment[equipmentKey].category_name) {
                c.equipmentEntries.push(equipmentKey);
                return true;
              }
              return false;
            });
          }
        }
      }

      return categories;
    },
    getCarImage(trimLevel) {
      return imageService.buildImageUrl(trimLevel);
    },
    getEngineFromTrimLevel(trimLevel) {
      let objectKey = undefined;
      const engine = trimLevel.min_engine;

      if ("SSCD" in engine) {
        objectKey = "SSCD";
      }

      if ("NSCD" in engine) {
        objectKey = "NSCD";
      }

      return engine[objectKey].engine;
    },
    openEquipmentCompairsion() {
      this.$bvModal.show("cd-modal-equipment-compairsion");
    },
    getIndexForTrimLevel(trimLevel) {
      let idx = -1;

      this.extendedTrimLevels.some((t, i) => {
        if (trimLevel.id === t.id) {
          idx = i;
          return true;
        }

        return false;
      });

      return idx;
    },
    processEquipmentCompairsion() {
      const vm = this;

      if (this.firstSelectedTrimLevel && this.secondSelectedTrimLevel) {
        const firstIndex = this.getIndexForTrimLevel(
          this.firstSelectedTrimLevel
        );
        const secondIndex = this.getIndexForTrimLevel(
          this.secondSelectedTrimLevel
        );
        let categories = [];

        this.compairsion.categories.forEach((cat) => {
          categories.push({ category: cat, equipmentList: [] });
        });

        for (let key in this.compairsion.equipment) {
          const equipment = this.compairsion.equipment[key];

          categories.some((cat, i) => {
            if (cat.category === equipment.category_name) {
              if (!vm.showDifference) {
                categories[i].equipmentList.push({
                  title: key,
                  cars: [
                    equipment.cars[firstIndex],
                    equipment.cars[secondIndex],
                  ],
                });
              } else {
                if (
                  equipment.cars[firstIndex] !== equipment.cars[secondIndex]
                ) {
                  categories[i].equipmentList.push({
                    title: key,
                    cars: [
                      equipment.cars[firstIndex],
                      equipment.cars[secondIndex],
                    ],
                  });
                }
              }
            }
          });
        }
        this.equipmentCompairsion = categories;
        return;
      }

      this.equipmentCompairsion = [];
    },
    selectTrimLevel(trimLevel) {
      this.$store.commit("carConfiguration/setCurrentTrimLevel", trimLevel);
      this.$store.commit("setShowEquipmentCompairsionLink", true);
      this.$router.push({
        name: "engines",
        params: {
          maker: this.currentMaker.label,
          model: this.currentModel.label,
          bodyDesign: this.currentBodyDesign.label,          
          trimLevel: trimLevel.label,
          trimLevelId: trimLevel.id
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      trimLevels: "trimLevels/trimLevels",
      currentMaker: "carConfiguration/currentMaker",
      currentModel: "carConfiguration/currentModel",
      currentBodyDesign: "carConfiguration/currentBodyDesign",
      conditions: "conditions/conditions",
    }),
    pricing() {
      return config.pricing;
    },
    equipmentTitle() {
      return this.selectedTrimLevel
        ? "Variante: " + this.selectedTrimLevel.label
        : "";
    },
  },
  components: {
    Box,
    CarImage
  },
  data: function () {
    return {
      extendedTrimLevels: [],
      compairsion: {},
      showDifference: false,
      selectedTrimLevel: undefined,
      firstSelectedTrimLevel: undefined,
      secondSelectedTrimLevel: undefined,
      equipmentCompairsion: [],
    };
  },
  watch: {
    conditions(val) {
      ConditionsService.initialize(val);
    },
    firstSelectedTrimLevel: function () {
      this.processEquipmentCompairsion();
    },
    secondSelectedTrimLevel: function () {
      this.processEquipmentCompairsion();
    },
    showDifference: function () {
      this.processEquipmentCompairsion();
    },
  },
  props: ["bodyDesignId"],
};
</script>

<style>

.cd-trim-level .card-text {
  padding: 15px;
  color: #757575;
}


.cd-model-image {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.cd-model-label,
.cd-trimlevel-label {
  color: #161616;
  font-weight: bold;
  font-size: 1rem;
}

.cd-original-price {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  color: #757575;
  font-size: .9rem;
}

.cd-entry.cd-trim-level .cd-trim-level-price {
  padding: 0 15px;
  text-align: left;
}

.cd-entry.cd-trim-level .more-link {
  color: #496cf6;
  font-size: .9rem;
  font-weight: bold;
  text-transform: uppercase;
}

.cd-entry.cd-trim-level .more-link-container {
  text-align: left;
  margin-left: 15px;
}


.cd-entry.cd-trim-level .card {
  height: auto;
  position: relative;
  min-height: auto;
  background-color: #F9F9F9;
}

.cd-entry.cd-trim-level ul {
  height: 298px;
  overflow: hidden;
  margin-top: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

.cd-entry.cd-trim-level .cd-trim-level-list li {
  font-size: .9rem;
  font-weight: normal;
}

.cd-entry.cd-model .card {
  overflow: hidden;
}

.cd-configure-hover {
  width: 100%;
  height: 55px;
  position: absolute;
  background-color: #f9f9f9;
  left: 0;
  bottom: -55px;
}

.cd-equipment-summary-header img {
  width: 100%;
}

.cd-entry.cd-model .card:hover .cd-configure-hover {
  bottom: 0px;
}

.cd-equipment-summary-first {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 50px;
  margin-bottom: 0;
}

#cd-modal-equipment-compairsion .cd-equipment-summary-first {
  margin-top: 35px;
}

#cd-modal-equipment-compairsion .modal-body {
  padding-top: 0px;
}

.cd-equipment-summary-second {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

#cd-modal-equipment-compairsion h5 {
  color: #003899;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
}

.cd-compairsion-selection-hint {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
}

.cd-equipment-row {
  padding: 15px 0;
  border-bottom: 1px solid #ebebeb;
}

#cd-modal-equipment-compairsion .custom-checkbox label {
  font-size: .9rem;
}
</style>
