<template>
  <b-container class="cd-container cd-box-component">
    <b-row>
     <b-col cols="6"
        @click="selectBodyDesign(bodyDesign)"
        class="cd-entry cd-model p-2"
        v-bind:key="bodyDesign.id"
        v-for="bodyDesign in bodyDesigns"
        xs="6"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <Box
          v-bind:image="imageUriForModel(bodyDesign)"
          class="cd-primary-sub-color"
        >
          <div class="">
            <p class="cd-model-label">{{ bodyDesign.label }}</p>
            <span class="cd-original-price"
              >uvp ab {{ bodyDesign.min_price_gross | formatCurrency }}&nbsp;€</span
            >
            <span class="cd-new-price"
              >ab {{getNewPrice(bodyDesign["min_price_" + pricing], bodyDesign) | formatCurrency}}&nbsp;€</span>
            <button type="button" class="btn btn-primary btn-hover">jetzt konfigurieren</button>
          </div>
        </Box>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Box from "../elements/Box";
import { mapGetters, mapActions } from "vuex";
import config from "../../config";
import api from "../../services/ApiService";
import store from "../../data/store";
import ConditionsService from "../../services/ConditionsService";

/* eslint-disable no-debugger */
export default {
  created() {
    this.$store.commit("setProgress", {
      progress: 3,
      title: "Karosserie",
      subline: "Wählen Sie eine Karosserie aus.",
    });
    this.$store.commit("setShowCarInfo", false);
  },
  beforeRouteEnter(to, from, next) {
    store.commit("setShowCarInfo", false);
    store.commit("setShowEquipmentCompairsionLink", false);
    next();
  },
  mounted() {
    ConditionsService.initialize(this.conditions);
    const route = this.$router;
    const vm = this;

    if (!this.$props.modelId) {
      return api.getMaker(route.currentRoute.params.maker).then((maker) => {
        vm.$store.commit("carConfiguration/setCurrentMaker", maker);
        return api
          .getModel(maker.id, route.currentRoute.params.model)
          .then((model) => {
            vm.$store.commit("carConfiguration/setCurrentModel", model);
            this.fetchBodyDesigns(model.id);
          });
      });
    } else {
      this.fetchBodyDesigns(this.$props.modelId);
    }
  },
  methods: {
    ...mapActions({
      fetchBodyDesigns: "bodyDesigns/fetchBodyDesigns",
    }),
    imageUriForModel(model) {
      let dbName = "SSCD" in model.photo_path ? "SSCD" : "NSCD";
      
      let segments = model.photo_path[dbName].split('/');

      segments[0] = this.maker.label.toUpperCase();
      segments[1] = this.model.label.toUpperCase();

      let finalPath = segments.join('/');

      return (
        config.apiUri +
        "/ngphotos/" +
        dbName +
        "/" +
        finalPath+
        "/" +
        model.photo_filename[dbName] +
        ".JPG"
      );
    },
    selectBodyDesign(bodyDesign) {
      const maker = this.$route.params.maker;
      const model = this.$route.params.model;

      this.$store.commit("carConfiguration/setCurrentBodyDesign", bodyDesign);
      this.$router.push({
        name: "trim_levels",
        params: {
          maker: maker,
          model: model,
          bodyDesign: bodyDesign.label,
          bodyDesignId: bodyDesign.id,
        },
      });
    },
    getNewPrice(oldPrice, data) {
      if (!this.conditions.size) {
        return oldPrice;
      }
      return ConditionsService.getNewPrice(oldPrice, data);
    },
  },
  watch: {
    conditions(val) {
      ConditionsService.initialize(val);
    },
  },
  computed: {
    ...mapGetters({
      bodyDesigns: "bodyDesigns/bodyDesigns",
      maker: "carConfiguration/currentMaker",
      model: "carConfiguration/currentModel",
      conditions: "conditions/conditions",
    }),
    pricing() {
      return config.pricing;
    },
    computedModelId() {
      return this.$props.modelId || this.fetchedModelId;
    },
  },
  components: {
    Box,
  },
  data: function () {
    return {
      fetchedModelId: undefined,
    };
  },
  props: ["modelId"],
};
</script>

<style>

.cd-original-price {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  color: #757575;
  font-size: .9rem;
}

.cd-entry.cd-model .card {
  position: relative;
  height: 100%;  
}

.cd-entry.cd-model .card {
  overflow: hidden;
}

.cd-configure-button {
  height: 40px;
  background: #003899;
  border-radius: 40px;
  font-size: .9rem;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  display: block;
  margin: 0px 10px;
  top: 10px;
}

.cd-configure-hover {
  width: 100%;
  height: 55px;
  position: absolute;
  background-color: #f9f9f9;
  left: 0;
  bottom: -45px;
}

.cd-entry.cd-model .card:hover .cd-configure-hover {
  bottom: 0px;
}
</style>
