<template>
  <b-img :src="finalImage" class="w-100" />
</template>

<script>
const placeholder = require("../../assets/images/placeholder.jpg");

/* eslint-disable no-debugger */
export default {
  created() {},
  mounted() {
      this.loadImage(this.$props.imageUrl);
  },
  methods: {
    loadImage(url) {
      const vm = this;
      vm.image = new Image();

      vm.image.onload = () => {        
        console.log("Setting image to: " + vm.image.src);
        vm.finalImage = vm.image.src;
        vm.$forceUpdate();
      };

      vm.image.onerror = () => {        
        console.log("Setting error image to: " + vm.image.src);
        vm.finalImage = placeholder;
        vm.$forceUpdate();
      };


      vm.image.src = url;
    },
  },
  watch: {
    imageUrl: function (value) {
      console.log("Image url changed");
      this.loadImage(value);      
    },
  },
  data() {
    return {
      image: undefined,
      finalImage: undefined,
    };
  },
  props: ["imageUrl"],
};
</script>

<style>
</style>