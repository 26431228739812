<template>
  <b-container fluid class="cd-contact-container offer">
    <b-row v-if="!pushSent" class="p-0">
      <b-col class="p-0" cols="12">
        <h3 class="cd-contact-headline">Persönliche Daten</h3></b-col
      >
      <b-col class="p-0"
        >
        <div>
          <b-row class="cd-form-container">

 <b-col cols="12">
              <label for="salutation" class="cd-form-label"
                >Anrede*</label
              >
              <v-select
                name="salutation"
                label="label"
                placeholder="Anrede wählen"
                :class="$v.salutation.$invalid && firstSubmit ? 'is-invalid' : ''"
                :options="salutations"
                v-model="salutation"
              ></v-select>
            </b-col>

            <b-col cols="12">
              <label for="company" class="cd-form-label">Firma</label>
              <b-form-input
                class="cd-form-input"
                name="company"
                v-model="company"
                placeholder="Firma"
              ></b-form-input>
            </b-col>

          <b-col
            md="12"
            lg="6"
             class="cd-form-row-start">
              <label for="firstName" class="cd-form-label">Vorname*</label>
              <b-form-input :state="$v.firstName.$invalid && firstSubmit ? false : null"
                class="cd-form-input"
                name="firstName"
                v-model="firstName"
                placeholder="Vorname"
              ></b-form-input>
          </b-col>

          <b-col
            md="12"
            lg="6"
            class="cd-form-row-end">
              <label for="lastName" class="cd-form-label">Nachname*</label>
              <b-form-input
                :state="$v.lastName.$invalid && firstSubmit ? false : null"
                class="cd-form-input"
                v-model="lastName"
                placeholder="Nachname"
              ></b-form-input>
            </b-col>

            <b-col
            md="12"
            lg="6"
             class="cd-form-row-start">
              <label for="zipCode" class="cd-form-label">PLZ*</label>
              <b-form-input :state="$v.zipCode.$invalid && firstSubmit ? false : null"
                class="cd-form-input"
                name="zipCode"
                v-model="zipCode"
                placeholder="PLZ"
              ></b-form-input>
          </b-col>

          <b-col
            md="12"
            lg="6"
            class="cd-form-row-end">
              <label for="city" class="cd-form-label">Ort*</label>
              <b-form-input
                :state="$v.city.$invalid && firstSubmit ? false : null"
                class="cd-form-input"
                v-model="city"
                placeholder="Ort"
              ></b-form-input>
            </b-col>

            <b-col cols="12">
            <label for="email" class="cd-form-label">E-Mail*</label>
            <b-form-input
              :state="$v.email.$invalid && firstSubmit ? false : null"
              class="cd-form-input"
              v-model="email"
              placeholder="E-Mail"
            ></b-form-input>
          </b-col>

          <b-col cols="12">
            <label for="phone" class="cd-form-label"
              >Telefonnummer</label
            >
            <b-form-input
              class="cd-form-input"
              v-model="phone"
              placeholder="Telefonnummer"
            ></b-form-input>
          </b-col>

          <b-col>
              <p class="cd-offer-subnote">Mit * markierte Felder sind Pflichtfelder</p>
            </b-col>

<b-col class="privacy"
cols="12" :class="$v.privacyPolicyAccepted.$invalid && firstSubmit ? 'is-checkbox-invalid' : ''">
            <b-form-checkbox id="privacyPolicyAccepted" v-model="privacyPolicyAccepted" name="privacyPolicyAccepted" >
            * Ich bin mit der Kontaktaufnahme durch die Emil Frey Digital Services GmbH, Cannstatter Straße 46, 70190 Stuttgart oder das jeweils für die Beratung/die Anfrage geeignete Emil Frey Autohaus einverstanden. Meine Einwilligung ist die Rechtsgrundlage für die Datenverarbeitung und die Daten sind nur für die Verarbeitung meiner Anfrage verwendbar. Sie ist freiwillig und jederzeit z.B. per E-Mail an datenschutz@emilfrey.de widerrufbar. Über diese E-Mailadresse erreiche ich auch den Datenschutzbeauftragten. Durch den Widerruf wird die Rechtmäßigkeit der Verarbeitung aufgrund der Einwilligung nicht berührt. Folge meines Widerrufes oder einer Verweigerung der Einwilligung ist, dass ich nicht kontaktiert werden kann.
            </b-form-checkbox>            
          </b-col>

          <b-col sm="12"
            md="12"
            lg="12"
            xl="12"  :class="$v.privacyPolicyAcceptedAdditional.$invalid && firstSubmit ? 'is-checkbox-invalid' : ''">
              <b-form-checkbox id="privacyPolicyAcceptedAdditional" v-model="privacyPolicyAcceptedAdditional" name="privacyPolicyAcceptedAdditional">
            * Mir ist bekannt, dass ich weitere Informationen (inklusive der vollständigen Händlerliste) in der Datenschutzerklärung <a :href="privacyPolicyUrl" target="_blank">Datenschutzhinweis</a> erhalte. Diese habe ich vollständig gelesen.
            </b-form-checkbox>            
            </b-col>


          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="pushSent">
        <b-col>
            Vielen Dank für Ihre Anfrage, die jetzt von uns geprüft wird.
            <p class="cd-redirect-button">
               <b-button
         @click="redirectToPage()"
          size="sm"
          class="btn cd-button cd-std-button cd-next-button cd-button-full-width btn-secondary btn-sm redirect-button">Zum Emil Frey Automarkt</b-button>
            </p>
        </b-col>
    </b-row>

    <b-modal id="cd-newconfiguration-modal" size="lg" title='Ihre Konfiguration ist abgeschlossen.' @ok="createNewConfiguration">
      <template #default="{}">
        <b-container>
          <p>Ihre Konfiguration wurde bereits erfolgreich übermittelt. Möchten Sie eine neue Konfiguration starten?</p>
        </b-container>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="sm" class="cd-button" @click="ok()"
          >Neue Konfiguration</b-button
        >
      </template>
    </b-modal>
    <b-container class="d-block d-xs-block d-sm-block d-md-block d-lg-none">
       <SidebarButton />
    </b-container>
    <b-container class="d-block d-xs-block d-sm-block d-md-block d-lg-none">
        <b-row class="cd-offer-container">
          <b-col class="text-center">
            <a
              href="#"
              class="cd-offer-link"
              @click.prevent="downloadOfferPdf()"
              >Angebot drucken</a
            >
          </b-col>
        </b-row>
          
    </b-container>

  </b-container>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import eventBus from "../../eventing/eventBus";
import ApiService from "../../services/ApiService";
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import SidebarButton from '../elements/SidebarButton.vue';
import TrackingService from "../../services/TrackingService";


export default {
   beforeRouteLeave(to, from, next) {
     if(to.name === 'makers') {
       next();
       return;
     }
     if(this.offerSubmitted) {        
        next(false);
        this.openNewConfigurationModal();
     }else {
       next();
     }
  },
  created() {
    this.$store.commit("setProgress", {
      progress: 8,
      title: "Anfrage",
      subline: "Kontaktdaten angeben und Angebot anfragen!",
    });
    this.$store.commit("setShowCarInfo", false);
  },
  mounted() {
    const vm = this;

    if (!this.externalId) {
      this.loadConfiguration(this.$props.configurationId).then(() => {});
    }

    eventBus.$on("submit-contact-form", () => { 
      if(vm.offerSubmitted) {
        return;
      }

      vm.firstSubmit = true;

      if(vm.$v.$invalid) {
        return;
      }

      vm.$store.commit("carConfiguration/setCurrentContactData", {
        title: vm.salutation,
        first_name: vm.firstName,
        last_name: vm.lastName,
        phone: vm.phone,
        email: vm.email,
        company_name: vm.company,
        zip_code: vm.zipCode,
        city: vm.city
      });

      vm.$store.commit("carConfiguration/setPriceNet", this.calculateBasePrice(true));
      vm.$store.commit("carConfiguration/setPriceGross", this.calculateBasePrice(false));

      this.saveConfiguration().then(() => {
        vm.$store.commit("loading/setLoading", true);        
        TrackingService.trackStage("Step 4 - Anfrage", `${vm.maker.label} - ${vm.model.label}`);
        return ApiService.dataPush({
            configuration_id: vm.externalId,
            user_id: ''
        }).then(() => {            
            vm.pushSent = true;
            vm.$store.commit("setOfferSubmitted", true);
            vm.$store.commit("loading/setLoading", false);
        });        
      });
    });
  },
  computed: {
    ...mapGetters({
      externalId: "carConfiguration/configurationId",
      engine: "carConfiguration/currentEngine",
      extras: "carConfiguration/currentExtras",
      maker: "carConfiguration/currentMaker",
      model: "carConfiguration/currentModel",
      privacyPolicyUrl: 'privacyPolicyUrl',
      offerSubmitted: 'offerSubmitted'
    }),
  },  
  data() {
    return {
      salutations: ["Herr", "Frau"],
      salutation: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      zipCode: "",
      city: "",
      pushSent: false,
      firstSubmit: false,
      privacyPolicyAccepted: undefined,
      privacyPolicyAcceptedAdditional: undefined
    };
  },
  methods: {
    ...mapActions({
      loadConfiguration: "carConfiguration/loadConfiguration",
      saveConfiguration: "carConfiguration/saveConfiguration",
    }),
    downloadOfferPdf() {
      return ApiService.getOfferPdf(this.externalId).then((pdfContent) => {
        /*debugger;*/
        const blob = new Blob([pdfContent], { type: "application/pdf" });
        const link = document.createElement("a");

        link.href = URL.createObjectURL(blob);
        link.download = "Angebot.pdf";
        link.click();

        URL.revokeObjectURL(blob);
        document.removeChild(link);
      });
    },
    openNewConfigurationModal() {
      this.$bvModal.show("cd-newconfiguration-modal");
    },
    createNewConfiguration() {
      this.$store.dispatch('carConfiguration/reset');
      this.$store.commit('offerSubmitted', false);
      this.$router.push({ name: "makers" });
    },
    calculatePriceOfExtras(netto) {
      let value = 0;
      this.extras.forEach((e) => {
        if (!netto) {
          if (e.price_gross) {
            value += e.price_gross;
          }
        }else {
           if (e.price_net) {
            value += e.price_net;
          } 
        }
      });

      return value;
    },
    calculateBasePrice(netto) {
      if (netto) {
        return this.engine
          ? this.engine.min_price_net + this.calculatePriceOfExtras(true)
          : 0;
      }
      return this.engine
        ? this.engine.min_price_gross + this.calculatePriceOfExtras(false)
        : 0;
    },
    redirectToPage() {
      window.location.href = "https://emilfrey.de ";
    }
    
  },
  validations: {
    salutation: {
      required
    },
    firstName: {
      required
    },
    lastName: {
      required
    },
    zipCode: {
      required
    },
    city: {
      required
    },
    email: {
      required,
      email
    },
    privacyPolicyAccepted: {
      checked: value => value === true
    },
    privacyPolicyAcceptedAdditional: {
      checked: value => value === true
    },

  },
  props: ["configurationId"],
  mixins: [validationMixin],
  components: {
    SidebarButton
  }
};
</script>

<style>

.offer {
  z-index: 0;
}

.privacy-policy-note,
.cd-offer-subnote {
  font-style: normal;
  font-weight: normal;
  font-size: .9rem;
  color: #757575;
}
.cd-form-label {
  font-style: normal;
  font-weight: bold;
  font-size: .9rem;
  color: #3c3c3c;
  padding-left: 18px;
}

.privacy,
.cd-form-container {
  padding: 20px 0;
}

.form-control.cd-form-input {
  border: none !important;
  border-radius: 40px !important;
  background: #f9f9f9 !important;
  height: 48px;
  padding: 0 1em;
}
.cd-contact-container {
  margin-top: 38px;
  padding: 0;
}

.cd-contact-headline {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  color: #3c3c3c;
}

.form-control.cd-form-input.is-invalid,
.is-invalid .vs__dropdown-toggle {
  border: 1px solid #dc3545!important;
}

.is-checkbox-invalid label {
  color: #dc3545;
}

.cd-redirect-button {
  margin-top:16px!important;
}


.redirect-button {
  padding:0px;
  width:100%;

}


</style>

