<template>
<ul :class="'icons d-flex flex-colum '+breakClass">
   <li v-bind:class="iconClass" v-html="value"></li>
   <slot></slot>
</ul>
</template>

<script>
export default {
  created() {},
  props: ['iconClass', 'value', 'break'],
  computed: {
    breakClass() {
      if(this.$props.break) {
        return "break"
      }

      return ""
    }
  }
};
</script>

<style>
ul.icons {
  list-style-type:none;
  margin-block-start: 1em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  display: inline-flex;
  padding-right: 20px;
}

</style>