<template>
  <b-card class="cd-box-component cd-box-color" :img-src="finalImage">
    <b-card-text>
      <slot />
    </b-card-text>
  </b-card>
</template>

<script>
const placeholder = require('../../assets/images/placeholder.jpg');

/* eslint-disable no-debugger */
export default {
  created() {},
  mounted() {
    let vm = this;

    if(!this.$props.image) {
      return
    }
    
    let img = new Image();

    img.onload = () => {
 
      vm.finalImage = img.src;
    };

    img.onerror = () => {      
      vm.finalImage = placeholder;
    };

    console.log("Loading "+this.$props.image);
    img.src = this.$props.image;
  },
  props: ["image"],
  data() {
    return {
      finalImage: undefined
    }
  },

};
</script>

<style>
.cd-box-component {
  position: relative;
  min-height: 160px;
  color: #9e9e9e;
  border: none;
  border-radius: 15px;
  border: none !important;
}


.cd-box-component:hover {
  cursor: pointer;
  filter: drop-shadow(0px 10px 28px rgba(22, 22, 22, 0.12));
}


.cd-box-component img {
  border-radius: 15px;
  padding: 10px;
}

.cd-box-color {
  background-color: #f9f9f9 !important;
}
</style>
