var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"cd-container cd-engines"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('StylizedRadioGroup',{attrs:{"options":_vm.engineFilters,"preSelection":_vm.selectedEngineFilter},model:{value:(_vm.selectedEngineFilter),callback:function ($$v) {_vm.selectedEngineFilter=$$v},expression:"selectedEngineFilter"}})],1)],1),_vm._l((_vm.categorizedEngines),function(category){return _c('b-row',{key:category.category,staticClass:"cd-engine-category-row"},[_c('b-col',{attrs:{"xs":"12","sm":"12","md":"3","lg":"3","xl":"3"}},[_c('span',{staticClass:"cd-engine-category"},[_vm._v(_vm._s(category.category))])]),_c('b-col',{staticClass:"cd-engine-representation",attrs:{"xs":"12","sm":"12","md":"9","lg":"9","xl":"9"}},_vm._l((category.engines),function(engine){return _c('b-card',{key:engine.id,on:{"click":function($event){$event.preventDefault();return _vm.selectEngine(engine)}}},[_c('b-row',[_c('b-col',{staticClass:"columns-left",attrs:{"xs":"12","sm":"12","md":"6","lg":"8","xl":"8"}},[_c('h5',[_vm._v(" "+_vm._s(engine.label)+" ")]),_c('p',{staticClass:"cd-engine-energy-efficiency cd-secondary-sub-color"},[(_vm.canCalculateCo2Class(engine))?_c('span',[_vm._v(" CO"),_c('sub',[_vm._v("2")]),_vm._v("-Klasse: ")]):_vm._e(),(!_vm.canCalculateCo2Class(engine))?_c('span',[_vm._v(" Energieeffizienzklasse: ")]):_vm._e(),_c('span',{attrs:{"id":'engine-target-' + engine.id}},[_vm._v(_vm._s(_vm.getCo2Class(engine)))]),(_vm.hasSecondaryCo2Class(engine))?_c('span',[_vm._v(_vm._s(' | '+_vm.secondaryCo2Class(engine)))]):_vm._e()]),_c('b-popover',{attrs:{"target":'engine-target-' + engine.id,"triggers":"hover"}},[_c('EnergyEfficiencyBox',{attrs:{"energyClass":engine.technical_data.energy_efficiency}})],1),_c('div',{staticClass:"cd-engine-info d-flex flex-wrap"},[_c('IconLabel',{attrs:{"iconClass":"engine-icon-motor-power","value":engine.technical_data.max_power_kw +
                  'kW (' +
                  engine.technical_data.max_power_ps +
                  ' PS)'}}),_c('IconLabel',{attrs:{"iconClass":"engine-icon-fuel-type","value":engine.fuel_type}}),_c('IconLabel',{attrs:{"iconClass":"engine-icon-transmission","value":engine.transmission}}),((engine.fuel_type != 'Elektro' || (engine.fuel_type === 'Elektro' && (_vm.getCo2(engine) != '-' && _vm.getCo2(engine) != '0' && _vm.getCo2(engine) != '0.0'))))?_c('IconLabel',{attrs:{"iconClass":"engine-icon-fuel-consumption","value":(engine.fuel_type == 'Wasserstoff' || engine.fuel_type == 'Erdgas') ? _vm.getGasConsumption(engine) + 'kg/100km' : _vm.getFuelConsumption(engine) + 'l/100km'}}):_vm._e(),(engine.fuel_type == 'Elektro' && !_vm.isPluginHybrid(engine))?_c('IconLabel',{attrs:{"iconClass":"engine-icon-fuel-consumption","value":_vm.getPowerConsumption(engine) + 'kWh/100km'}}):_vm._e(),(_vm.isPluginHybrid(engine))?_c('IconLabel',{attrs:{"iconClass":"engine-icon-fuel-consumption","value":'Kombiniert gewichtet:<br/>'+_vm.getPowerConsumption(engine) + 'kWh/100km' + _vm.getPluginHybridAddition(engine)}}):_vm._e(),(_vm.isPluginHybrid(engine))?_c('IconLabel',{attrs:{"iconClass":"engine-icon-fuel-consumption","value":'Bei entladener Batterie kombiniert:<br/>'+_vm.getPowerConsumptionBatteryDepleted(engine) + 'l/100km'}}):_vm._e(),(!_vm.isPluginHybrid(engine))?_c('IconLabel',{attrs:{"iconClass":"engine-icon-emission","value":_vm.getCo2(engine) + ' g/km'}},[_c('span',{staticClass:"cd-efficiency-mark",attrs:{"id":'energyefficiency-target-' + engine.id}},[_c('span',{class:'mark-background ' +
                      _vm.calculateEnergyClass(
                        engine
                      )}),_c('span',{staticClass:"cd-efficiency-marklabel"},[_vm._v(_vm._s(_vm.getCo2Class(engine)))]),(_vm.hasSecondaryCo2Class(engine))?_c('span',{class:'mark-background secondary ' +_vm.calculateSecondaryEnergyClass(engine)}):_vm._e(),(_vm.hasSecondaryCo2Class(engine))?_c('span',{staticClass:"cd-efficiency-marklabel secondary"},[_vm._v(_vm._s(_vm.secondaryCo2Class(engine)))]):_vm._e(),_c('b-popover',{attrs:{"target":'energyefficiency-target-' + engine.id,"triggers":"hover"}},[_c('EnergyEfficiencyBox',{attrs:{"engine":engine}})],1)],1)]):_vm._e(),(_vm.isPluginHybrid(engine))?_c('IconLabel',{attrs:{"iconClass":"engine-icon-emission","value":'Gewichtet kombiniert: '+_vm.getCo2(engine) + 'g/km<br/>Bei entladener Batterie kombiniert: '+_vm.getCo2DepletedBattery(engine) + 'g/km'}},[(engine.technical_data.energy_efficiency || engine.technical_data.wltpData)?_c('span',{staticClass:"cd-efficiency-mark",attrs:{"id":'energyefficiency-target-' + engine.id}},[_c('span',{class:'mark-background ' +
                      _vm.calculateEnergyClass(
                        engine
                      )}),_c('span',{staticClass:"cd-efficiency-marklabel"},[_vm._v(_vm._s(_vm.getCo2Class(engine)))]),(_vm.hasSecondaryCo2Class(engine))?_c('span',{class:'mark-background secondary ' +_vm.calculateSecondaryEnergyClass(engine)}):_vm._e(),(_vm.hasSecondaryCo2Class(engine))?_c('span',{staticClass:"cd-efficiency-marklabel secondary"},[_vm._v(_vm._s(_vm.secondaryCo2Class(engine)))]):_vm._e(),_c('b-popover',{attrs:{"target":'energyefficiency-target-' + engine.id,"triggers":"hover"}},[_c('EnergyEfficiencyBox',{attrs:{"engine":engine}})],1)],1):_vm._e()]):_vm._e(),(!_vm.isPluginHybrid(engine))?_c('div',[_c('br'),_vm._v("Alle Verbrauchs- und Emissions-Angaben sind kombinierte Werte.")]):_vm._e()],1)],1),_c('b-col',{staticClass:"columns-right",attrs:{"xs":"12","sm":"12","md":"6","lg":"4","xl":"4"}},[_c('span',{staticClass:"cd-original-price"},[_vm._v("uvp ab "+_vm._s(_vm._f("formatCurrency")(engine.min_price_gross))+" €")]),_c('p',{staticClass:"cd-new-price"},[_vm._v(" ab "+_vm._s(_vm._f("formatCurrency")(_vm.getNewPrice(engine["min_price_" + _vm.pricing], engine)))+"  € ")]),_c('button',{staticClass:"btn btn-primary btn-hover",attrs:{"type":"button"}},[_vm._v(" Auswählen ")])])],1)],1)}),1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }