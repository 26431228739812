<template>
  <b-container class="cd-container">
    <b-row d-flex flex-column>
     <b-col cols="6"
        @click="selectMaker(maker)"
        class="cd-entry makers p-2"
        v-bind:key="maker.id"
        v-for="maker in makers"
        sm="6"
        md="4"
        lg="3"
        xl="3"
      >
        <Box class="bottom-text cd-maker maker-image">
          <div :class="makerImage(maker)">
            
          </div>
          <p>
            {{maker.label}}
          </p>
        </Box>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Box from "../elements/Box";
import { mapGetters, mapActions } from "vuex";
import store from "../../data/store";
import TrackingService from "../../services/TrackingService";
export default {
  created() {
    this.$store.commit("setProgress", {
      progress: 1,
      title: "Marke",
      subline: "Konfigurieren Sie Ihr neues Fahrzeug, wie Sie es wollen.",
    });
    this.fetchMakers();
  },
  mounted() {},
  /* eslint-disable no-debugger */
  beforeRouteEnter(to, from, next) {
    store.commit("setShowCarInfo", false);
    store.commit("setShowEquipmentCompairsionLink", false);
    next();
  },
  methods: {
    ...mapActions({
      fetchMakers: "makers/fetchMakers",
    }),
    selectMaker(maker) {
      this.$store.commit("carConfiguration/setCurrentMaker", maker);
      
      TrackingService.trackStage("Step 1 - Einstieg", maker.label);

      this.$router.push({
        name: "models",
        params: { maker: maker.label, makerId: maker.id },
      });
    },
    makerImage(maker) {

      let label = maker.label.replace('ŠKODA', 'SKODA').toLowerCase().replace(' ','-');
      return "maker-image img-" + label;
    },
  },
  computed: {
    ...mapGetters({
      makers: "makers/makers",
    }),
  },
  components: {
    Box,
  },
};
</script>

<style>

</style>
