import config from "./../config";

export default {
    buildImageUrl(obj) {
        let dbName = "SSCD" in obj.photo_path ? "SSCD" : "NSCD";

        return (
            config.apiUri +
            "/ngphotos/" +
            dbName +
            "/" +
            obj.photo_path[dbName] +
            "/" +
            obj.photo_filename[dbName] +
            ".JPG"
        );
    },
    buildImageUrlForPerspective(obj, perspective, color, maker, model) {
        if(!obj) {
            return null;
        }
        let dbName = "SSCD" in obj.photo_path ? "SSCD" : "NSCD";


        let segments = obj.photo_path[dbName].split('/');
  
        segments[0] = maker.label.toUpperCase();
        segments[1] = model.label.toUpperCase();
  
        let finalPath = segments.join('/');

        let imagePath = config.apiUri +
        "/ngphotos/" +
        dbName +
        "/" +
        finalPath +
        "/" +
        obj.photo_filename[dbName];

        if(color) {
            imagePath+="_"+perspective;
            imagePath = imagePath + '_' + color.option_code;
        }

        imagePath = imagePath + ".JPG";

        return imagePath;
    }
}