<template>
  <div class="v-stepper d-none">
    <div class="cd-stepper font-weight-bold">
      <template v-for="i in steps">
        <div v-bind:key="' step'+i" :class="'stepper text-center d-none d-xl-block' + (i === currentStep ? ' active d-block' : '')" >
          <div
            :class="'step' + (i === currentStep ? ' active' : '')" 
          >
            {{ i }}
          </div>
          <p class="pt-1">{{getStepLabel(i)}}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-debugger */
export default {
  created() {},
  mounted() {},  
  props: ["steps", "currentStep"],
  methods: {
    getStepLabel(i) {
      let idx = i-1;
      const labels = [
        'Marke',
        'Modell',
        'Karosserie',
        'Ausstattungslinie',
        'Motor',
        'Ausstattung',
        'Übersicht',
        'Bestätigung'
      ];

      return labels[idx];
    }
  },
  data() {
    return {};
  },
};
</script>

<style>

.stepper > p {
  font-size: .8rem;
  color: #d6d6d6;

 
}

.active > p {
     color: #003899;   
}

.cd-stepper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cd-stepper .step {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.cd-stepper .step {
  font-size: .9rem;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: none;
  border: 1px solid #d6d6d6;
  color: #d6d6d6;
}

.cd-stepper .step.active {
  color: #fff;
  background: #003899;
}

.cd-stepper hr {
  border-color: rgba(0, 0, 0, 0.12);
  align-self: center;
  margin: 0 16px;
  display: block;
  flex: 1 1 0px;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0 0;
}
</style>
