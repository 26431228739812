<template>
  <b-container class="cd-container summary">
    <b-row>
      <b-col sm="12" md="12" lg="12" xl="12" class="p-0">
        <b-container class="cd-summary-container p-0">
          <b-row>
            <b-col class="cd-summary-container-main" sm="12" md="12" lg="6" xl="6">
              <h3 class="cd-summary-subheadline cd-primary-black">
                Fahrzeugdetails
              </h3>
              <span class="cd-summary-title"> —<br />Marke </span>
              <span class="cd-summary-value">
                {{ maker ? maker.label : "" }}
              </span>

              <span class="cd-summary-title"> —<br />Modell </span>
              <span class="cd-summary-value">
                {{ model ? model.label : "" }}
              </span>

              <span class="cd-summary-title"> —<br />Karosserie </span>
              <span class="cd-summary-value">
                {{ bodyDesign ? bodyDesign.label : "" }}
              </span>

              <span class="cd-summary-title"> —<br />Ausstattungslinie </span>
              <span class="cd-summary-value">
                {{ trimLevel ? trimLevel.label : "" }}
              </span>

              <span class="cd-summary-title"> —<br />Farbe </span>
              <span class="cd-summary-value">
                {{ selectedColor ? selectedColor.label : "" }}
              </span>
            </b-col>
            <b-col sm="12" md="12" lg="6" xl="6">
              <h3 class="cd-summary-subheadline cd-primary-black">
                Performance
              </h3>
              <span class="cd-summary-title"> —<br />Kraftstoff </span>
              <span class="cd-summary-value">
                {{ engine ? engine.fuel_type : "" }}
              </span>

              <span class="cd-summary-title"> —<br />Leistung </span>
              <span class="cd-summary-value">
                {{ engine ? engine.technical_data.max_power_kw : "" }}kW/{{ engine ? engine.technical_data.max_power_ps : "" }} PS
                
              </span>

              <span class="cd-summary-title">
                —<br />
                {{ !hasWltpData(engine) ? 'Energieeffizienzklasse' : 'CO2-Klasse'}}
              </span>
              <span class="cd-summary-value">
                {{ getCo2Class(engine) }}
              </span>

              <span class="cd-summary-title" v-if="!isPluginHybrid(engine)">
                —<br />CO2-Emissionen kombiniert
              </span>
              <span class="cd-summary-value">
                {{ engine ? getCo2(engine) + "g/km" : "" }}
              </span>

              <span class="cd-summary-title" > —<br />Verbrauch kombiniert{{ isPluginHybrid(engine) ? ' gewichtet': '' }}</span>
              <span class="cd-summary-value" v-if="engine && (engine.fuel_type != 'Elektro' || (engine.fuel_type === 'Elektro' && (getCo2(engine) !== '-' && getCo2(engine) != '0' && getCo2(engine) != '0.0'  ) ))">                                  
                {{                                    
                  engine 
                    ? ( isPluginHybrid(engine) ?  getFuelConsumption(engine) +'l '+getFuel(engine)+'/100km' : (engine.fuel_type == 'Wasserstoff' || engine.fuel_type == 'Erdgas') ? getGasConsumption(engine) + 'kg/100km' : getFuelConsumption(engine) + 'l/100km' )                    
                    : ''
                                                                      
                }}
              </span>
              <span class="cd-summary-value" v-if="engine && engine.fuel_type == 'Elektro'">                
                {{
                 engine ? getPowerConsumption(engine) + 'kWh/100km' : '-'
                }}
              </span>

              <span class="cd-summary-title" v-if="isPluginHybrid(engine)"> —<br />Verbrauch kombiniert bei entladener Batterie</span>
              <span class="cd-summary-value" v-if="isPluginHybrid(engine)">                
                {{
                   getFuelConsumptionDepletedBattery(engine) + 'l/100km'
                }}
              </span>
            </b-col>
          </b-row>

          <b-row>
            <b-container class="cd-summary-extras-categories-container">
              <b-row>
                <b-col>
                  <h3 class="cd-summary-subheadline cd-primary-black">
                    Sonderausstattung
                  </h3>
                </b-col>
              </b-row>
              <b-row
                v-for="extraCat in extrasCategories"
                v-bind:key="extraCat.categoryName"
                class="cd-summary-extras-category-row"
              >
                <b-col cols="12">
                  <h3 class="cd-extra-category-headline">
                    {{ extraCat.categoryName }}
                  </h3>
                  <b-container class="cd-summary-extras-items">
                    <b-row
                      v-for="extra in extraCat.extras"
                      v-bind:key="extra.id"
                    >
                      <b-col cols="10">
                        <ul>
                          <li>
                            <span class="cd-summary-extra-label">{{
                              extra.label
                            }}</span>
                          </li>
                        </ul>
                      </b-col>
                      <b-col cols="2">
                        <span
                          class="cd-summary-extra-price"
                          v-if="extra.price_gross"
                          >{{ extra.price_gross | formatCurrency }}&nbsp;€</span
                        >
                        <span
                          class="cd-summary-extra-price"
                          v-if="!extra.price_gross"
                          >inkl.</span
                        >
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-row>

          <b-row>
            <b-col cols="12">
              <h3 v-if="!hasWltpData(engine)" class="cd-summary-subheadline cd-primary-black energy-eficiency-headline">
                CO2 - Effizienz
              </h3>
              <div class="co2class-box-big">
                <EnergyEfficiencyBox
                  :engine="engine"
                />
              </div>
              <p class="cd-subnote">
                Auf der Grundlage der gemessenen CO2-Emissionen unter Berücksichtigung der Masse des Fahrzeugs ermittelt. Weitere Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer Personenkraftwagen können dem Leitfaden über den Kraftstoffverbrauch und die
                CO2-Emissionen neuer Personenkraftwagen entnommen werden, der an allen Verkaufsstellen und bei <a href="https://www.dat.de" target="_blank">www.dat.de</a>
                unentgeltlich erhältlich ist.<br /><br />Informationen über Kraftstoffverbrauch, CO2-Emissionen und Stromverbrauch i.S.d. Pkw-EnVKV downloaden.
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-container class="d-block d-xs-block d-sm-block d-md-block d-lg-none">
      <b-col>
        <SidebarButton />
      </b-col>
    </b-container>
    <b-container class="d-block d-xs-block d-sm-block d-md-block d-lg-none">
        <!--<b-row class="cd-offer-container">
          <b-col class="text-center">
          <a
              href="#"
              class="cd-offer-link"
              @click.prevent="downloadOfferPdf()"
              >Angebot drucken</a
            >
          </b-col>
        </b-row>-->
          
    </b-container>
  </b-container>
</template>

<script>
import store from "../../data/store";
import { mapGetters, mapActions } from "vuex";
import EnergyEfficiencyBox from "../elements/EnergyEfficiencyBox.vue";
import ExtrasService from "../../services/ExtrasService";
import SidebarButton from '../elements/SidebarButton.vue';
import valueService from '../../services/ValueService';
import ApiClient from "../../services/ApiService";
/* eslint-disable no-debugger */
export default {
  created() {
    this.$store.commit("setProgress", {
      progress: 7,
      title: "Übersicht",
      subline: "Überprüfen Sie Ihre Konfiguration!",
    });
    this.$store.commit("setShowCarInfo", false);
  },
  mounted() {
    if (!this.externalId) {
      this.loadConfiguration(this.$props.configurationId).then(() => {});
    }

    if (this.$props.offer === "pdf") {
      this.$store.commit("setShowOfferLink", true);
    }
  },
  beforeRouteEnter(to, from, next) {
    store.commit("setShowCarInfo", false);
    store.commit("setShowEquipmentCompairsionLink", false);
    store.commit("setOfferSubmitted", false);
    next();
  },
  computed: {
    ...mapGetters({
      externalId: "carConfiguration/configurationId",
      maker: "carConfiguration/currentMaker",
      model: "carConfiguration/currentModel",
      bodyDesign: "carConfiguration/currentBodyDesign",
      trimLevel: "carConfiguration/currentTrimLevel",
      engine: "carConfiguration/currentEngine",
      extras: "carConfiguration/currentExtras",
    }),
    extrasCategories() {
      
      let categories = [];
      const vm = this;
      function isCategoryAvailable(categoryName) {
        return categories.some((c) => {
          if (c.categoryName === categoryName) {
            return true;
          }

          return false;
        });
      }

      let chosenExtras = this.getChosenExtras();

      chosenExtras.forEach((e) => {
        let categoryName = e.category_name;

        if (!e.selected) {
          return;
        }

        if (e.category_name === "Hidden") {
          if (e.option_type === "C") {
            categoryName = "Farben";
          } else {
           return;
          }
        }

        let requirements = vm.getRequirementsFor(e);
        requirements.push(e);

        if (!isCategoryAvailable(categoryName)) {          
          categories.push({
            categoryName: categoryName,
            extras: requirements,
          });
        } else {
          categories.some((c) => {
            if (c.categoryName === categoryName) {
              c.extras.push(...requirements);
              return true;
            }

            return false;
          });
        }
      });

      return categories;
    },
    selectedColor() {
      let color = undefined;

      this.extras.some((e) => {
        if (e.option_type === "C" && e.category_name === "Farben" && e.selected) {
          color = e;
          return true;
        }

        return false;
      });

      return color;
    },
  },
  methods: {
    ...mapActions({
      loadConfiguration: "carConfiguration/loadConfiguration",
    }),
    downloadOfferPdf() {
      return ApiClient.getOfferPdf(this.externalId).then((pdfContent) => {
        /*debugger;*/
        const blob = new Blob([pdfContent], { type: "application/pdf" });
        const link = document.createElement("a");

        link.href = URL.createObjectURL(blob);
        link.download = "Angebot.pdf";
        link.click();

        URL.revokeObjectURL(blob);
        document.removeChild(link);
      });
    },
    getCo2(engine) {
      return valueService.getCo2(engine);
    },
    getFuel(engine) {
      return valueService.getFuel(engine);
    },
    getCo2DepletedBattery(engine) {
      return valueService.getCo2DepletedBattery(engine);
    },
    getCo2Class(engine) { 
      return valueService.getWltpCo2Class(engine);
    },
    isPluginHybrid(engine) {
      return valueService.isPluginHybrid(engine);
    },
    getSecondaryCo2Class(engine) {
      return valueService.getSecondaryCo2Class(engine);
    },
    getFuelConsumption(engine) {
      return valueService.getWltpConsumptionCombined(engine);
    },
    getFuelConsumptionDepletedBattery(engine) {
      return valueService.getPowerConsumptionDepletedBattery(engine);
    },
    getPowerConsumption(engine) {
      return valueService.getPowerConsumption(engine);
    },
    getGasConsumption(engine) {
      return valueService.getGasConsumption(engine);
    },
    hasWltpData(engine) {
      return valueService.canCalculateCo2Class(engine);
    },
    getPackageContents(item) {
      return ExtrasService.getPackageContents(item, this.extras);
    },
    getChosenExtras() {
      const vm = this;
      let result = vm.extras.filter(function (item) {
        if (Object.hasOwnProperty.call(item, "includedByPackage") && item.includedByPackage) {
          return false;
        }

        return true;
      });

      result.map(function (item) {
        if (item.option_type === 'P') {
          item.packageContents = ExtrasService.getPackageContents(item, vm.extras);
        }
      });

      return result;
    },
    getRequirementsFor(item) {
      let requirements = [];

      this.extras.forEach(e => {
        if(e.requiredBy === item.id) {
          requirements.push(e);
        }
      })

      return requirements;
    }
  },
  props: ["configurationId", "offer"],
  components: {
    EnergyEfficiencyBox,
    SidebarButton
  },
};
</script>

<style>
.summary {
  z-index: 0;
}

.co2class-box-big {
  width: 320px;
}

.energy-eficiency-headline {
  margin-top:20px;
}

.cd-summary-extras-categories-container .col-10,
.cd-summary-extras-items {
  padding: 0;
}

.cd-summary-extras-category-row {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d6d6d6;
}

.cd-summary-extras-items span,
.cd-summary-extras-items ul li {
  font-style: normal;
  font-weight: normal;
  font-size: .9rem;
  color: #757575;
}

.cd-summary-extras-items ul {
  list-style: square;
  padding-left: 18px;
  margin-bottom: 0;
}

.cd-summary-extras-items .col-2 {
  text-align: right;
}

.cd-container.summary {
  position: relative;
}
.cd-summary-container {
  margin-top: 38px;
}
.cd-summary-container h3.cd-summary-subheadline,
span.cd-summary-subheadline {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 24px;
}

.cd-summary-title,
.cd-summary-value {
  display: block;
}

.cd-summary-title {
  font-size: .9rem;
}

.cd-summary-value {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 24px;
}

.cd-subnote {
  font-size: .9rem;
}

.cd-extra-category-headline {
  font-style: normal;
  font-weight: bold;
  font-size: .9rem;
  margin-bottom: 20px;
}
</style>