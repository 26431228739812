<template>
  <div class="cd-energy-efficiency-box-wrapper">
    <h3  v-html="popoverTitle"></h3>
    <b-container>
      <b-row>
        <b-col></b-col>
        <b-col><span v-if="hasSecondaryClass">gewichtet kombiniert</span></b-col>
        <b-col><span v-if="hasSecondaryClass">bei entladener Batterie</span></b-col>
      </b-row>
    </b-container>
    
    <div :class="'cd-energy-efficiency-box' + boxClassAddition">      
      <span v-if="hasSecondaryClass" class="cd-energy-efficiency-box-spacer"></span>
      <span
        :class="
          'energy-efficiency-marker '+markerClass
        "
        >{{ getCo2Class }}</span
      >
      <span v-if="hasSecondaryClass"
        :class="
          'energy-efficiency-marker secondary '+secondaryMarkerClass
        "
        >{{ getSecondaryCo2Class }}</span>    
      
    </div>    
  </div>
</template>

<script>
import ValueService from '../../services/ValueService';

export default {
  created() {},
  data() {
    return {
      energyEfficiencyClass: this.$props.engine.technical_data.energy_efficiency,
      wltpData: this.$props.engine.technical_data.wltpData,      
    };
  },
  computed: {
      markerClass() {
          let markerClass = "";
          if(!ValueService.canCalculateCo2Class(this.$props.engine)) {
            markerClass = this.energyEfficiencyClass.toLowerCase();
            markerClass = markerClass.replace(/\+/g, 'p');
          }else {
            
            markerClass = ValueService.getWltpCo2Class(this.$props.engine).toLowerCase();
            
          }

          return 'energy-efficiency-' + markerClass;
      },
      popoverTitle() {
        return ValueService.canCalculateCo2Class(this.$props.engine) ? 'CO<sub>2</sub>-Klasse' : 'Energieeffizienzklasse';
      },
      secondaryMarkerClass() {
        if(this.wltpData) {
          return 'energy-efficiency-'+this.getSecondaryCo2Class.toLowerCase();
        }

        return "";        
      },
      boxClassAddition() {
        let additionalClass = "";

        if(ValueService.canCalculateCo2Class(this.$props.engine)) {
          additionalClass += " wltp"
        }

        return additionalClass;
      },
      getCo2Class() {
        
        return ValueService.getWltpCo2Class(this.$props.engine);
      },
      getSecondaryCo2Class() {            
          return this.wltpData.calculations.secondaryCo2Class;        
      },
      hasSecondaryClass() {
        return this.wltpData && this.wltpData.calculations.secondaryCo2Class;
      }
  },
  props: ["engine"],
};
</script>

<style>
.popover {
  max-width: 350px!important;
}

.cd-energy-efficiency-box {
  display: block;
  position: relative;
  background: url("./../../assets/images/energieeffizienzklasse.png") no-repeat;
  height: 230px;
  width: 270px;
}

.cd-energy-efficiency-box.wltp.secondary-class {
  width: 350px;
}

.cd-energy-efficiency-box.wltp {
  background: url("./../../assets/images/co2class.png") no-repeat;
}

.energy-efficiency-marker {
  position: absolute;
  right: 0;
  background: url("./../../assets/images/energy_marker.svg") no-repeat;
  filter: invert(18%) sepia(7%) saturate(157%) hue-rotate(169deg)
    brightness(97%) contrast(88%);
  width: 43px;
  color: #fff;
  text-align: center;
  height: 25px;
}

.secondary-class .energy-efficiency-marker {
  right:170px;
}

.secondary-class .energy-efficiency-marker.secondary {
  right:0px;
}

.cd-energy-efficiency-box-spacer {
  position: absolute;
  width:1px;
  height: 230px;
  background: #000000;
  top:0;
  right: 120px;
}



.energy-efficiency-appp {
  top: 0px;
}
.energy-efficiency-app {
  top: 26px;
}
.energy-efficiency-ap {
    top: 46px;
}
.energy-efficiency-a {
    top: 70px;
}
.energy-efficiency-b {
    top: 92px;
}
.energy-efficiency-c {
    top: 112px;
}
.energy-efficiency-d {
    top: 136px;
}
.energy-efficiency-e {
    top: 157px;
}
.energy-efficiency-f {
    top: 180px;
}
.energy-efficiency-g {
    top: 204px;
}

.cd-energy-efficiency-box.wltp .energy-efficiency-a {
  top: 15px;
}

.cd-energy-efficiency-box.wltp .energy-efficiency-b {
  top: 42px;
}

.cd-energy-efficiency-box.wltp .energy-efficiency-c {
  top: 72px;
}

.cd-energy-efficiency-box.wltp .energy-efficiency-d {
  top: 102px;
}

.cd-energy-efficiency-box.wltp .energy-efficiency-e {
  top: 132px;
}

.cd-energy-efficiency-box.wltp .energy-efficiency-f {
  top: 162px;
}

.cd-energy-efficiency-box.wltp .energy-efficiency-g {
  top: 192px;
}
</style>